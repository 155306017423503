import React from "react"

const FlightImg = () => {
    return (
        <>
            <img src="../images2/plane.png" alt="plane"/>
        </>
    )
}
export default FlightImg
