import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import FlightSlider from "../../components/pageComponents/flightSlider";
import ExclusiveDeals from "../../components/pageComponents/exclusiveDeals";
import {Radio, Form, Modal, Select, DatePicker} from "../../components/Elements";
import {adultOptions, childrenOptions, flightTypeOption, JourneyTypeOptions} from "../../components/utils/appUtils";
import {GetEachFormFields} from "../../components/utils/formUtils";
import {airLinesFxn, searchFlightFxn} from "./actions";
import CityTypeheadComponent from "../../components/CityTypeheadComponent";
import {useDispatch} from "react-redux";

const initState = {
    flightType: "One-Way",
    journeyType: "1",
    adultCount: 1,
    childCount: 0,
    infantCount: 0,
}
const PassengersGridComponent = (props) => {
    let {name, message, onChange, value} = props;
    const events = {
        increaseFxn: () => {
            if (value !== undefined && parseFloat(value) < 10) {
                onChange(parseFloat(value) + 1);
            }
        },
        decreaseFxn: () => {
            if (value && parseFloat(value) > (name == "Adult" ? 1 : 0)) {
                onChange((value ? parseFloat(value) : 1) - 1);
            }
        }
    }
    return (
        <>
            <div className="grid-box">
                <h5>
                    <span>{name}</span> {message}
                </h5>
                <div className="input-box">
                    <div className="qty-input">
                        <a
                            className="qty-count qty-count--minus"
                            data-action="minus"
                            type="button" onClick={events.decreaseFxn}>
                            -
                        </a>
                        <input
                            className="product-qty"
                            type="number"
                            name="product-qty"
                            value={value}
                            min={0}
                            max={10}
                            defaultValue={1}
                        />
                        <a
                            className="qty-count qty-count--add"
                            data-action="add"
                            type="button"
                            onClick={events.increaseFxn}
                        >
                            +
                        </a>
                    </div>
                </div>
            </div>

        </>
    )
}
const CabinComponent = (props) => {
    let {onChange, state} = props;
    let [cabinState, setCabinState] = useState(state)
    let [visible, setVisible] = useState(false)
    const events = {
        _updateCabinState: (data) => {
            setCabinState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },
        submitCabinState: () => {
            onChange(cabinState)
            setVisible(false)
        }
    }
    return (
        <>
            <div className="cabin-box">
                <input
                    type="text"
                    defaultValue="Cabin Class"
                    className="form-control cursor-pointer"
                    readOnly={true}
                    onClick={() => setVisible(true)}
                />
            </div>
            {visible ?
                <Modal
                    visible={visible}
                    width={"28%"}
                    onClose={() => setVisible(false)}
                    title={'Cabin Type'}>
                    <div className={'cabin-type-modal'}>
                        <div className="cabin-option">
                            <ul>
                                {JourneyTypeOptions.map((item) => {
                                    return (
                                        <li key={item.display}>
                                            <div className="box">
                                                <input
                                                    type="radio"
                                                    value={item.id}
                                                    checked={item.id == cabinState.journeyType}
                                                    name="cabin-class" onChange={() => {
                                                    events._updateCabinState({journeyType: item.id})
                                                }}/>
                                                <label>{item.display}</label>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        <h5
                            className="modal-title mt-3"
                            id="exampleModalLabel">
                            Passengers
                        </h5>
                        <div className="choose-passanger">
                            <PassengersGridComponent name={'Adult'}
                                                     value={cabinState.adultCount}
                                                     message={"12+ Years"} onChange={(value) => {
                                events._updateCabinState({adultCount: value})
                            }}/>
                            <PassengersGridComponent name={'Children'}
                                                     value={cabinState.childCount}
                                                     message={"2-12 Yrs"} onChange={(value) => {
                                events._updateCabinState({childCount: value})
                            }}/>
                            <PassengersGridComponent name={'Seat Infant'}
                                                     value={cabinState.infantCount}
                                                     message={"< 2 Yrs"} onChange={(value) => {
                                events._updateCabinState({infantCount: value})
                            }}/>

                        </div>
                        <div className="modal-footer p-0 border-0">
                            <a onClick={() => {
                                events.submitCabinState()
                            }}
                               className="btn btn-primary w-100 m-0">
                                Save &amp; Search
                            </a>
                        </div>
                    </div>
                </Modal> : null}

        </>
    )
}
const BookFlightComponent = (props) => {
    const navigate = useNavigate();
    let dispatch = useDispatch()
    let [state, setState] = useState(initState);
    let [airlineList, setAirlineList] = useState([])
    const events = {
        _updateState: (data) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },
        loadAirLine: async () => {
            let params = {
                results: 10000,
                count: 10000,
            }
            let {data} = await airLinesFxn(params);
            setAirlineList(data)
        },
        handleSearchFlight: async () => {
            dispatch({type: "SEARCH_FLIGHT", flightObj: state})
            navigate('/find-flight')
        }
    }
    useEffect(() => {
        events.loadAirLine()
    }, [])

    return (
        <>
            <div className="radio-box mb-3">
                <Radio
                    name={'flightType'}
                    options={flightTypeOption}
                    value={state.flightType}
                    onChange={(value) => {
                        events._updateState({flightType: value})
                    }}/>
            </div>
            <Form>
                <div className="row row-gap-3">
                    <div className={'col-md-4'}>
                        <CityTypeheadComponent
                            placeholder={"Departing From?"}
                            cityId={state.origin} onSelect={(value) => {
                            events._updateState({origin: value})
                        }}/>
                    </div>
                    <div className={'col-md-4'}>
                        <CityTypeheadComponent
                            placeholder={"Going To?"}
                            cityId={state.destination} onSelect={(value) => {
                            events._updateState({destination: value})
                        }}/>
                    </div>
                    <div className={'col-md-4'}>
                        <DatePicker
                            placeholder={'Departure Date'}
                            value={state.preferredDepartureTime}
                            onChange={(value) => {
                                events._updateState({preferredDepartureTime: value})
                            }}/>
                    </div>
                    <div className={'col-md-4'}>
                        <Select
                            options={airlineList}
                            keyAccessor={(option) => option.airLineCode}
                            valueAccessor={(option) => option.airLineName}
                            placeholder={"Preferred Airline"}
                            required={true}
                            showSearch={true}
                            allowClear={true}
                            onChange={(value) => {
                                events._updateState({preferredAirlines: value})
                            }}
                        />
                    </div>

                    <div className="col-lg-4 col-12">
                        <CabinComponent state={state} onChange={(data) => {
                            events._updateState(data)
                        }}/>
                    </div>
                </div>
                <button onClick={() => {
                    events.handleSearchFlight()
                }} className="btn btn-primary mt-4 w-100">
                    Find Your Flight
                </button>


            </Form>
            <div className="radio-box mt-4 justify-content-center">
                <select
                    name="preferred-airline"
                    id="preferredAirline"
                    className="form-control w-auto rounded-pill"
                >
                    <option selected="">Select Preferred Airline</option>
                    <option value={1}>Airline 1</option>
                    <option value={2}>Airline 2</option>
                </select>
                <ul>
                    <li>
                        <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault2"
                            id="Direct-Flight"
                        />
                        <span/>
                        <label>Direct Flight</label>
                    </li>
                    <li>
                        <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault2"
                            id="Regular-Fares"
                        />
                        <span/>
                        <label>Regular Fares</label>
                    </li>
                    <li>
                        <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault2"
                            id="Student-Fares"
                        />
                        <span/>
                        <label>Student Fares</label>
                    </li>
                    <li>
                        <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault2"
                            id="Senior-Citizen-Fares"
                        />
                        <span/>
                        <label>Senior Citizen Fares</label>
                    </li>
                </ul>
            </div>
        </>
    )
}
const ManageBookingComponent = () => {
    return (
        <>
            <div className="tab-head">
                <h6 className="m-0">My Booking</h6>
            </div>
            <form>
                <div className="row row-gap-3">
                    <div className="col-lg-3 col-12">
                        <input
                            type="number"
                            className="form-control"
                            placeholder="PNR Number"
                        />
                    </div>
                    <div className="col-lg-3 col-12">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Destination"
                        />
                    </div>
                    <div className="col-lg-3 col-12">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Passenger Name"
                        />
                    </div>
                    <div className="col-lg-3 col-12">
                        <button type="submit" className="btn btn-primary w-100">
                            Search
                        </button>
                    </div>
                </div>
            </form>
            <div className="track-flights">
                <div className="accordion" id="accordionPanelsStayOpenExample">
                    <div className="accordion-item">
                        <h2
                            className="accordion-header"
                            id="panelsStayOpen-headingOne"
                        >
                            <ul>
                                <li className="p-3 head-accordion">
                                    <h6 className="pnr">PNR</h6>
                                    <div className="d-flex align-items-center">
                                        <h6
                                            style={{
                                                justifyContent: "center",
                                                textAlign: "center"
                                            }}
                                        >
                                            NEW DELHI{" "}
                                            <small className="d-block">Terminal 2</small>
                                        </h6>
                                        <span>
                            <img src="images2/plane.png" alt="plane"/>
                          </span>
                                        <h6
                                            style={{
                                                justifyContent: "center",
                                                textAlign: "center"
                                            }}
                                        >
                                            BOMBAY <small className="d-block">Terminal 3</small>
                                        </h6>
                                    </div>
                                    <div>
                                        <a
                                            href="#"
                                            className="btn btn-primary min-width-btn me-2"
                                        >
                                            Details
                                        </a>
                                        <a
                                            href=""
                                            data-bs-toggle="collapse"
                                            data-bs-target="#panelsStayOpen-collapseOne"
                                            aria-expanded="true"
                                            aria-controls="panelsStayOpen-collapseOne"
                                            className="btn p-0"
                                        >
                                            <img
                                                src="images2/arrow-up.png"
                                                alt="arrow-up"
                                                width={28}
                                            />
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </h2>
                        <div
                            id="panelsStayOpen-collapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="panelsStayOpen-headingOne"
                        >
                            <div className="accordion-body">
                                <ul>
                                    <li className="p-0">
                                        <h6>
                                            02 <small className="d-block">Passanges</small>
                                        </h6>
                                        <h6>
                                            21 Sept, 24{" "}
                                            <small className="d-block">Departure</small>
                                        </h6>
                                        <h6>
                                            Departure Time{" "}
                                            <small className="d-block">04:45 PM</small>
                                        </h6>
                                        <h6>
                                            23 Sept, 24{" "}
                                            <small className="d-block">Arrival</small>
                                        </h6>
                                        <h6>
                                            Arrival Time{" "}
                                            <small className="d-block">07:55 PM</small>
                                        </h6>
                                        <h6>
                                            Class: <small className="d-block">Business</small>
                                        </h6>
                                        <h6>
                                            Journey Time:{" "}
                                            <small className="d-block">3H 15m</small>
                                        </h6>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2
                            className="accordion-header"
                            id="panelsStayOpen-headingTwo"
                        >
                            <ul>
                                <li className="p-3 head-accordion">
                                    <h6 className="pnr">PNR</h6>
                                    <div className="d-flex align-items-center">
                                        <h6
                                            style={{
                                                justifyContent: "center",
                                                textAlign: "center"
                                            }}
                                        >
                                            NEW DELHI{" "}
                                            <small className="d-block">Terminal 2</small>
                                        </h6>
                                        <span>
                            <img src="images2/plane.png" alt="plane"/>
                          </span>
                                        <h6
                                            style={{
                                                justifyContent: "center",
                                                textAlign: "center"
                                            }}
                                        >
                                            BOMBAY <small className="d-block">Terminal 3</small>
                                        </h6>
                                    </div>
                                    <div>
                                        <a
                                            href="#"
                                            className="btn btn-primary min-width-btn me-2"
                                        >
                                            Details
                                        </a>
                                        <a
                                            href=""
                                            data-bs-toggle="collapse"
                                            data-bs-target="#panelsStayOpen-collapseTwo"
                                            aria-expanded="true"
                                            aria-controls="panelsStayOpen-collapseTwo"
                                            className="btn p-0"
                                        >
                                            <img
                                                src="images2/arrow-up.png"
                                                alt="arrow-up"
                                                width={28}
                                            />
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </h2>
                        <div
                            id="panelsStayOpen-collapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="panelsStayOpen-headingTwo"
                        >
                            <div className="accordion-body">
                                <ul>
                                    <li className="p-0">
                                        <h6>
                                            02 <small className="d-block">Passanges</small>
                                        </h6>
                                        <h6>
                                            21 Sept, 24{" "}
                                            <small className="d-block">Departure</small>
                                        </h6>
                                        <h6>
                                            Departure Time{" "}
                                            <small className="d-block">04:45 PM</small>
                                        </h6>
                                        <h6>
                                            23 Sept, 24{" "}
                                            <small className="d-block">Arrival</small>
                                        </h6>
                                        <h6>
                                            Arrival Time{" "}
                                            <small className="d-block">07:55 PM</small>
                                        </h6>
                                        <h6>
                                            Class: <small className="d-block">Business</small>
                                        </h6>
                                        <h6>
                                            Journey Time:{" "}
                                            <small className="d-block">3H 15m</small>
                                        </h6>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const FLightStatusComponent = () => {
    return (
        <>
            <div className="tab-head">
                <h6 className="m-0">Flight Tracking &amp; Status</h6>
            </div>
            <form>
                <div className="row row-gap-3">
                    <div className="col-lg-3 col-12">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Airlines"
                        />
                    </div>
                    <div className="col-lg-3 col-12">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Flight Code"
                        />
                    </div>
                    <div className="col-lg-3 col-12">
                        <input
                            type="date"
                            className="form-control"
                            placeholder="Date"
                        />
                    </div>
                    <div className="col-lg-3 col-12">
                        <button type="submit" className="btn btn-primary w-100">
                            Search
                        </button>
                    </div>
                </div>
            </form>
            <div className="track-flights">
                <div className="accordion" id="accordionPanelsStayOpenExampleTwo">
                    <div className="accordion-item">
                        <h2
                            className="accordion-header"
                            id="panelsStayOpen-headingOne"
                        >
                            <ul>
                                <li className="p-3 head-accordion">
                                    <h6>Direct</h6>
                                    <h6
                                        style={{
                                            justifyContent: "center",
                                            textAlign: "center"
                                        }}
                                    >
                                        NEW DELHI{" "}
                                        <small className="d-block">Terminal 2</small>
                                    </h6>
                                    <span>
                          <img src="images2/plane.png" alt="plane"/>
                        </span>
                                    <h6
                                        style={{
                                            justifyContent: "center",
                                            textAlign: "center"
                                        }}
                                    >
                                        BOMBAY <small className="d-block">Terminal 3</small>
                                    </h6>
                                    <h6>
                                        6624485{" "}
                                        <small className="d-block">Flight Number</small>
                                    </h6>
                                    <h6>
                                        02:00 <small className="d-block">Departure</small>
                                    </h6>
                                    <h6>
                                        08:00 <small className="d-block">Arrival</small>
                                    </h6>
                                    <a href="#" className="btn btn-primary">
                                        Now at New York
                                    </a>
                                    <a
                                        href=""
                                        data-bs-toggle="collapse"
                                        data-bs-target="#panelsStayOpen-collapseOne"
                                        aria-expanded="true"
                                        aria-controls="panelsStayOpen-collapseOne"
                                        className="btn p-0"
                                    >
                                        <img
                                            src="images2/arrow-up.png"
                                            alt="arrow-up"
                                            width={28}
                                        />
                                    </a>
                                </li>
                            </ul>
                        </h2>
                        <div
                            id="panelsStayOpen-collapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="panelsStayOpen-headingOne"
                        >
                            <div className="accordion-body">
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                                Ut, optio!
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2
                            className="accordion-header"
                            id="panelsStayOpen-headingTwo"
                        >
                            <ul>
                                <li className="p-3 head-accordion">
                                    <h6>Direct</h6>
                                    <h6
                                        style={{
                                            justifyContent: "center",
                                            textAlign: "center"
                                        }}
                                    >
                                        NEW DELHI{" "}
                                        <small className="d-block">Terminal 2</small>
                                    </h6>
                                    <span>
                          <img src="images2/plane.png" alt="plane"/>
                        </span>
                                    <h6
                                        style={{
                                            justifyContent: "center",
                                            textAlign: "center"
                                        }}
                                    >
                                        BOMBAY <small className="d-block">Terminal 3</small>
                                    </h6>
                                    <h6>
                                        6624485{" "}
                                        <small className="d-block">Flight Number</small>
                                    </h6>
                                    <h6>
                                        02:00 <small className="d-block">Departure</small>
                                    </h6>
                                    <h6>
                                        08:00 <small className="d-block">Arrival</small>
                                    </h6>
                                    <a href="#" className="btn btn-primary">
                                        Now at New York
                                    </a>
                                    <a
                                        href=""
                                        data-bs-toggle="collapse"
                                        data-bs-target="#panelsStayOpen-collapseOne"
                                        aria-expanded="true"
                                        aria-controls="panelsStayOpen-collapseOne"
                                        className="btn p-0"
                                    >
                                        <img
                                            src="images2/arrow-up.png"
                                            alt="arrow-up"
                                            width={28}
                                        />
                                    </a>
                                </li>
                            </ul>
                        </h2>
                        <div
                            id="panelsStayOpen-collapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="panelsStayOpen-headingTwo"
                        >
                            <div className="accordion-body">
                                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                                Dolores exercitationem dolorum vel fugiat mollitia,
                                recusandae assumenda impedit at distinctio ducimus.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const LastSearchFlightComponent = () => {
    return (
        <>
            last search
        </>
    )
}

const FlightBooking = (props) => {
    const navigate = useNavigate();
    return (
        <div>
            <div className="flight-booking spacing-80 pb-0">
                <div className="banner-box">
                    <img
                        src="../images2/home-banner.jpg"
                        alt="home-banner"
                        className="w-100"
                    />
                </div>
                <div className="container position-relative">
                    <div className="col-lg-12">
                        <ul className="nav nav-tabs justify-content-center border-0 gap-3"
                            id="myTab"
                            role="tablist">
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link active rounded-pill border-0"
                                    id="book-flight-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#book-flight"
                                    type="button"
                                    role="tab"
                                    aria-controls="book-flight"
                                    aria-selected="true"
                                >
                                    <img src="images2/book-flights.png" alt=""/> book flight
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link rounded-pill border-0"
                                    id="manage-booking-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#manage-booking"
                                    type="button"
                                    role="tab"
                                    aria-controls="manage-booking"
                                    aria-selected="false"
                                >
                                    <img src="images2/manage-bookings.png" alt=""/> manage
                                    booking
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link rounded-pill border-0"
                                    id="flight-status-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#flight-status"
                                    type="button"
                                    role="tab"
                                    aria-controls="flight-status"
                                    aria-selected="false"
                                >
                                    <img src="images2/flight-status.png" alt=""/> flight status
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link rounded-pill border-0"
                                    id="last-search-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#last-search"
                                    type="button"
                                    role="tab"
                                    aria-controls="last-search"
                                    aria-selected="false"
                                >
                                    <img src="images2/last-search-flight.png" alt=""/> last
                                    search flights
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content p-3 p-lg-4 bg-white mt-4" id="myTabContent">
                            <div
                                className="tab-pane fade show active"
                                id="book-flight"
                                role="tabpanel"
                                aria-labelledby="book-flight-tab">
                                <BookFlightComponent {...props}/>
                            </div>
                            <div
                                className="tab-pane fade"
                                id="manage-booking"
                                role="tabpanel"
                                aria-labelledby="manage-booking-tab">
                                <ManageBookingComponent/>
                            </div>
                            <div
                                className="tab-pane fade"
                                id="flight-status"
                                role="tabpanel"
                                aria-labelledby="flight-status-tab">
                                <FLightStatusComponent/>
                            </div>
                            <div
                                className="tab-pane fade"
                                id="last-search"
                                role="tabpanel"
                                aria-labelledby="last-search-tab">
                                <LastSearchFlightComponent/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FlightSlider/>
            <ExclusiveDeals/>
        </div>
    );
}

export default Form.create()(FlightBooking);
