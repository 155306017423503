import {
    searchFlightUrl,
    flightsCodeUrl,
    airLinesUrl,
    flightsFearRuleUrl,
    flightsFearQuoteUrl, lccFlightTicketUrl, noLccFlightTicketUrl, singleTicketUrl
} from "../apis";
import {customAxios as axios} from "../../../request";
import {notification} from "../../../components/Elements";
import {showLoader, hideLoader} from "../../../actions/loader";

export const airLinesFxn = async (valData) => {
    let config = {
        params: {...valData}
    }
    let {data} = await axios.get(airLinesUrl(), config)
    return data.data;
}
export const flightsCodeFxn = async (valData) => {
    let config = {
        params: {...valData}
    }
    let {data} = await axios.get(flightsCodeUrl(), config)
    return data.data;
}

export const searchFlightFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(searchFlightUrl(), valData)
    dispatch(hideLoader())
    return data;
}
export const flightsFearRuleFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(flightsFearRuleUrl(), valData)
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data;
}

export const flightsFearQuoteFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(flightsFearQuoteUrl(), valData)
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data;
}

export const lccFlightTicketFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(lccFlightTicketUrl(), valData)
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data;
}

export const noLccFlightTicketFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(noLccFlightTicketUrl(), valData)
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data;
}

export const singleTicketFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(singleTicketUrl(), valData)
    dispatch(hideLoader())
    /* if (!data.error) {
         notification.success({
             message: data.message || 'Success'
         })
     } else {
         notification.error({
             message: data.message || 'Error'
         })
     }*/
    return data;
}
