import React from "react"
import FlightImg from "../../components/flightImg";
import {displayDate, displayTime, minToHours} from "../../components/utils/appUtils";
import {Popover, Tooltip} from "../../components/Elements"

const FlightAddress = ({data}) => {
    return (
        <>
            <small className="d-block">
                {data && data.Airport && data.Airport.AirportName ? data.Airport.AirportName : ""}
                {data && data.Airport && data.Airport.AirportCode ? ` (${data.Airport.AirportCode}) ` : ""}
            </small>
            <small className="d-block">
                {data && data.Airport && data.Airport.Terminal ? `${data.Airport.Terminal}, ` : ""}
                {data && data.Airport && data.Airport.CityName ? `${data.Airport.CityName} ` : ""}
                {/*{data && data.Airport && data.Airport.CountryName ? data.Airport.CountryName : ""}*/}
            </small>
        </>
    )
}


const NonStopComponent = (props) => {
    let {data} = props;
    let {Segments, FareRules: fareRules} = data;
    let allSegmentData = Segments[0];
    let segmentStarting = allSegmentData[0];
    let segmentEnding = allSegmentData[allSegmentData.length - 1];

    const contentBody = (
        <>
            <table className={'table table-bordered'}>
                <tr>
                    <th>Airline Info</th>
                    <th>Flight Info</th>
                    <th>Dept.T</th>
                    <th>Arr.T</th>
                </tr>
                {allSegmentData && allSegmentData.length ? allSegmentData.map((item) => {
                    let {Airline, Origin, Destination} = item;
                    return (
                        <>
                            <tr>
                                <td>
                                    {Airline && Airline.AirlineCode} {Airline && Airline.FlightNumber} {Airline && Airline.FareClass}
                                </td>
                                <td className={'justify-content-between'}>
                                    {Origin ? <>
                                        {Origin.Airport && Origin.Airport.AirportCode ? Origin.Airport.AirportCode : ""}
                                        ({Origin.DepTime ? displayTime(Origin.DepTime) : ""})
                                    </> : null}
                                    &nbsp;-&nbsp;

                                    {Destination ? <>
                                        {Destination.Airport && Destination.Airport.AirportCode ? Destination.Airport.AirportCode : ""}
                                        ({Destination.ArrTime ? displayTime(Destination.ArrTime) : ""})
                                    </> : null}
                                </td>
                                <td>

                                </td>
                                <td>

                                </td>
                            </tr>

                        </>
                    )
                }) : null}
            </table>
        </>
    )

    return (
        <>
            <div className={'accordion-flight-track'}>

                <div className="track-flights">
                    <ul>
                        <li className="p-0">
                          <span className="logo">
                            <img src={`../airlineLogos/${data.AirlineCode}.gif`} alt=""/>
                          </span>
                            <h6>
                                {segmentStarting.Airline && segmentStarting.Airline.AirlineName}
                                <small className="d-block">
                                    {segmentStarting.Airline && segmentStarting.Airline.AirlineCode ? segmentStarting.Airline.AirlineCode : ""}&nbsp;-&nbsp;
                                    {segmentStarting.Airline && segmentStarting.Airline.FlightNumber ? segmentStarting.Airline.FlightNumber : ""}&nbsp;&nbsp;
                                    {segmentStarting.Airline && segmentStarting.Airline.FareClass ? segmentStarting.Airline.FareClass : ""}
                                </small>
                                <small className="d-block">
                                    {data['IsLCC'] ? "LCC" : "Non LCC"}
                                </small>
                            </h6>
                            <div className="d-flex align-items-center" style={{gap: 30}}>
                                <h6>
                                    {segmentStarting.Origin && segmentStarting.Origin.DepTime ? <>
                                        {displayDate(segmentStarting.Origin.DepTime)}
                                    </> : null}
                                    {segmentStarting.Origin ? <FlightAddress data={segmentStarting.Origin}/> : null}
                                </h6>
                                <span>
                                 <Popover content={contentBody}>
                                      <p>Non-Stop</p>
                                 </Popover>
                                <FlightImg/>
                            </span>
                                <h6>
                                    {segmentEnding.Destination && segmentEnding.Destination.ArrTime ? <>
                                        {displayDate(segmentEnding.Destination.ArrTime)}
                                    </> : null}
                                    {segmentEnding.Destination ?
                                        <FlightAddress data={segmentEnding.Destination}/> : null}
                                </h6>
                                <h6>
                                    {segmentEnding && segmentEnding.Duration ? minToHours(segmentEnding.Duration) : ""}
                                    <small className="d-block">Economy Class</small>
                                    <small className="d-block">
                                        {data.IsRefundable ? "Refundable" : "Non Refundable"}
                                    </small>
                                </h6>
                            </div>
                        </li>
                    </ul>
                    <p className="line">Adult: Check-in 2 Piece (15KG) + Cabin: 1 Piece (7KG)</p>
                </div>
            </div>

        </>
    )
}

const WithStopComponent = (props) => {
    let {data} = props;
    let {Segments, FareRules: fareRules} = data;
    let allSegmentData = Segments[0];
    let segmentStarting = allSegmentData[0];
    let segmentEnding = allSegmentData[allSegmentData.length - 1];
    let originObj = fareRules[0];
    let destinationObj = fareRules[fareRules.length - 1];
    const contentBody = (
        <>
            <table className={'table table-bordered'}>
                <tr>
                    <th>Airline Info</th>
                    <th>Flight Info</th>
                    <th>Dept.T</th>
                    <th>Arr.T</th>
                </tr>
                {allSegmentData && allSegmentData.length ? allSegmentData.map((item) => {
                    let {Airline, Origin, Destination} = item;
                    return (
                        <>
                            <tr>
                                <td>
                                    {Airline && Airline.AirlineCode} {Airline && Airline.FlightNumber} {Airline && Airline.FareClass}
                                </td>
                                <td className={'justify-content-between'}>
                                    {Origin ? <>
                                        {Origin.Airport && Origin.Airport.AirportCode ? Origin.Airport.AirportCode : ""}
                                        ({Origin.DepTime ? displayTime(Origin.DepTime) : ""})
                                    </> : null}
                                    &nbsp;-&nbsp;

                                    {Destination ? <>
                                        {Destination.Airport && Destination.Airport.AirportCode ? Destination.Airport.AirportCode : ""}
                                        ({Destination.ArrTime ? displayTime(Destination.ArrTime) : ""})
                                    </> : null}
                                </td>
                                <td>

                                </td>
                                <td>

                                </td>
                            </tr>

                        </>
                    )
                }) : null}
            </table>
        </>
    )

    let stopsComponent = (
        <>
            <span><FlightImg/></span>
            <h6>
                <Popover content={contentBody}>
                    <>{allSegmentData.length - 1} Stop(s)</>
                </Popover>
                <small className="d-block">
                    {originObj && originObj.Origin ? originObj.Origin : ""}
                    &nbsp;-&nbsp;
                    {destinationObj && destinationObj.Destination ? destinationObj.Destination : ""}
                </small>
                <small className="d-block">
                    {segmentStarting && segmentStarting.Duration ? <>
                        {minToHours(segmentStarting.Duration)}
                    </> : null}
                </small>
            </h6>
            <span><FlightImg/></span>
        </>
    )

    return (
        <>
            <div className="track-flights">
                <ul>
                    <li className="p-0">
                       <span className="logo">
                            <img src={`../airlineLogos/${data.AirlineCode}.gif`} alt=""/>
                        </span>
                        <h6>{segmentStarting.Airline && segmentStarting.Airline.AirlineName}
                            <small className="d-block">
                                {segmentStarting.Airline && segmentStarting.Airline.AirlineCode ? segmentStarting.Airline.AirlineCode : ""}&nbsp;-&nbsp;
                                {segmentStarting.Airline && segmentStarting.Airline.FlightNumber ? segmentStarting.Airline.FlightNumber : ""}&nbsp;&nbsp;
                                {segmentStarting.Airline && segmentStarting.Airline.FareClass ? segmentStarting.Airline.FareClass : ""}
                            </small>
                            <small className="d-block">
                                {data['IsLCC'] ? "LCC" : "Non LCC"}

                                {/*{segmentData && segmentData.Duration ? minToHours(segmentData.Duration) : ""}*/}
                            </small>
                        </h6>

                        <div
                            className="d-flex align-items-center"
                            style={{gap: '30px'}}>
                            <h6>
                                {segmentStarting.Origin && segmentStarting.Origin.DepTime ? <>
                                    {displayDate(segmentStarting.Origin.DepTime)}
                                </> : null}
                                {segmentStarting.Origin ? <FlightAddress data={segmentStarting.Origin}/> : null}
                            </h6>

                            {stopsComponent}

                            <h6>
                                {segmentEnding.Destination && segmentEnding.Destination.ArrTime ? <>
                                    {displayDate(segmentEnding.Destination.ArrTime)}
                                </> : null}

                                {segmentEnding.Destination ? <FlightAddress data={segmentEnding.Destination}/> : null}
                            </h6>


                            {allSegmentData.length == 1 ? <h6>
                                {segmentEnding && segmentEnding.Duration ? minToHours(segmentEnding.Duration) : ""}
                                <small
                                    className="d-block">Economy
                                    Class</small>
                                <small className="d-block">
                                    {data.IsRefundable ? "Refundable" : "Non Refundable"}

                                </small>
                            </h6> : null}
                        </div>
                    </li>
                </ul>

            </div>

        </>
    )
}

const FlightInfoBox = (props) => {
    let {data} = props;
    let {Segments} = data;
    let allSegmentData = Segments[0];

    return (
        <>
            {allSegmentData.length > 1 ? <WithStopComponent {...props}/> : <NonStopComponent {...props}/>}
        </>
    )
}
export default FlightInfoBox
