import React from "react";
import {Outlet, useLocation} from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
import LoginHeader from "./loginHeader";

const Layout = () => {
    // const isLoggedIn = !!localStorage.getItem("token");
    const location = useLocation();
    const isLoggedIn = ["/dashboard", "/find-booking", "/find-flight", "/checkout"].includes(location.pathname);
    return (
        <>
            {isLoggedIn ? <LoginHeader/> : <Header/>}
            <main className="flex-shrink-0">
                <Outlet/>
            </main>
            <Footer/>
        </>
    );
};

export default Layout;
