export const searchFlightUrl = () => {
    return "/x-travel/search"
}

export const airLinesUrl = () => {
    return "/x-travel/air-lines"
}

export const flightsCodeUrl = () => {
    return "/x-travel/flights-code"
}

export const flightsFearRuleUrl = () => {
    return "/x-travel/fear-rules"
}
export const flightsFearQuoteUrl = () => {
    return "/x-travel/fear-quote"
}

export const lccFlightTicketUrl = () => {
    return "/x-travel/ticket-flight-lcc"
}

export const noLccFlightTicketUrl = () => {
    return "/x-travel/ticket-flight-no-lcc"
}

export const singleTicketUrl = () => {
    return "/x-travel/single-ticket"
}
