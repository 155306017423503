import React, {Component} from "react";
import S from "string";
import {
    Form, Radio, Select, DatePicker
} from "../Elements";
import _ from "lodash"
import moment from "moment";
import {daysArr, monthArr, yearArr} from "./appUtils";

const FormItem = Form.Item;

const styles = {
    mainDiv: {
        position: "relative",
    },
    loadingBox: {
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        backgroundColor: "rgba(255, 255,255, 0.5)",
        textAlign: "center",
        paddingTop: "10%",
    },
    selfAlign: {
        display: "flex",
        marginLeft: 5,
        fontWeight: 600,
        fontFamily: 'Public Sans',
        marginBottom: 5,
    },
    labelRow: {
        display: "flex",
        justifyContent: "space-between",
        color: "#555",
    },
};

export const dateOfBirthPicker = (data) => {
    data = data.replace("/", "").replace(/[^\d]/, "");
    let day = data.substring(0, 2);
    let month = data.substring(2, 4);
    let year = data.substring(4, 8);
    let date = "";
    if (day > 31) {
        day = "0" + day;
    }
    if (month > 12) {
        month = "0" + month;
    }
    /*if (day) {
        date = date + day;
    }*/
    if (month) {
        month = "/" + month;
    }
    if (year) {
        year = "/" + year;
    }
    return `${day}${month}${year}`;
};


class SimpleFormElement extends Component {
    state = {
        tempFiles: [],
        previewImage: null,
        previewVisible: false,
    };

    constructor(props) {
        super(props);
        this.fileRef = React.createRef()
    }

    section = (type = "text") => {
        let errors;
        let x = _.clone(this.props);
        let {item} = this.props;

        // delete item['required']
        let {
            placeholder = "",
            multiple = false,
            value,
            onChange,
            minHeight = 50
        } = item;
        switch (type) {
            case "select":
                if (!x.options) x.options = [];
                if (!x.item.defaultValue)
                    x.item.defaultValue = {key: "Please Select"};
                let defaultValue = multiple
                    ? value !== undefined
                        ? value
                        : []
                    : value ? value
                        : undefined;
                return (
                    <>
                        <Select
                            {...x}
                            id={item.id}
                            showSearch={true}
                            name={item.name}
                            multiple={multiple} onChange={onChange}/>
                    </>
                );
            case "textarea":
                return (
                    <>
                        <textarea className="form-control" {...x} {...item} style={{minHeight: minHeight}}></textarea>

                    </>
                );
            case "checkbox":
                return (
                    <>

                    </>
                );
            case "radio":
                return (
                    <>
                        <Radio {...x} {...item} value={this.props.value}/>

                    </>
                );
            case "date":
                return (
                    <>
                        <DatePicker
                            {...x}
                            format={item.format}
                            className={"form-control"}
                            onChange={onChange}
                        />

                    </>
                );
            case "time":
                return (
                    <>

                    </>
                );
            case "phone":
                return (
                    <>
                        {/*   <PhoneInput
                            {...x}
                            format={item.format}
                            onChange={onChange}
                        />
                        {(errors = getFieldError(item.key)) ? (
                            <div className={"errorMsg"}>{errors.join(",")}</div>
                        ) : null}*/}
                    </>
                );

            case "file":
                let filename = x['value']
                let refreshKey = x['value'] ? x['value'] : moment()
                if (x.value && x.value.name) {
                    x.value.filename = x.value.name
                } else {
                    if (this.fileRef.current) {
                        this.fileRef.current.value = ""
                    }
                }
                delete x['value']
                return (
                    <>
                        <div className="input-group">
                            <input
                                type={type}
                                placeholder={`${placeholder}`}
                                {...x}
                                {...item}
                                ref={this.fileRef}
                                className="form-control"
                                onChange={({target}) => {
                                    item.onChange(target.files)
                                }}
                            />
                        </div>

                    </>
                );
            case "custom":
                return (
                    <>
                        <input
                            type={type}
                            placeholder={`${placeholder}`}
                            {...item}
                            {...x}
                            className="form-control"
                        />

                    </>
                );
            case "dateOfBirth":
                return (
                    <>
                        <div className="input-group">
                            <input
                                placeholder={`${placeholder}`}
                                {...item}
                                {...x}
                                type={'text'}
                                className="form-control"
                                onChange={({target}) => {
                                    let valueDate = target.value ? dateOfBirthPicker(target.value) : null
                                    item.onChange(valueDate)
                                }}
                            />
                            <span className="input-group-text cursor-pointer dateBirthBox">
                                <i className="bx bx-calendar"></i>
                            </span>
                        </div>

                    </>
                );
            case "customDate":
                return (
                    <>
                        <div className="input-group">
                            <div className={'custom-date-grid w-100'}>
                                <Select
                                    {...item}
                                    {...x}
                                    placeholder={'Date'}
                                    className="form-control custom-form-control"
                                    options={daysArr()}
                                    onChange={({target}) => {
                                        onChange({date: target.value})
                                    }}
                                />
                                <Select
                                    {...item}
                                    {...x}
                                    options={monthArr}
                                    placeholder={`Month`}
                                    className="form-control custom-form-control"
                                    keyAccessor={(option) => option.name}
                                    valueAccessor={(option) => option.value}
                                    onChange={({target}) => {
                                        onChange({month: target.value})
                                    }}
                                />
                                <Select
                                    {...item}
                                    {...x}
                                    options={yearArr(item.passengerType)}
                                    showSearch={true}
                                    placeholder={`Year`}
                                    className="form-control custom-form-control"
                                    onChange={({target}) => {
                                        onChange({year: target.value})
                                    }}
                                />

                            </div>

                        </div>

                    </>
                );
            default:
                return (
                    <>
                        <input
                            placeholder={`${placeholder}`}
                            {...item}
                            {...x}
                            type={'text'}
                            onChange={({target}) => {
                                onChange(target.value)
                            }}
                            className="form-control custom-form-control"
                        />
                    </>
                );
        }
    };

    render() {
        const {item} = this.props;
        const {type, label, extra, required = false, customBtn = "", showStar = false} = item;
        return (
            <React.Fragment>
                <div className={'mb-2 d-flex flex-column'}>
                    {label ?
                        <label className="form-label float-start">{label} {required ?
                            <strong>*</strong> : ""}</label> : null}
                    {this.section(type)}
                </div>
            </React.Fragment>
        );
    }
}

class getAllFormFields extends Component {
    state = {
        fileUploads: [],
    };

    checkEmailValidation = (rule, value, callback) => {
        let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (rule.required && !value) {
            callback(`${rule.label} a Mandatory Field`)
            return
        }
        if (value) {
            if (rule.key == "email") {
                if (!value.match(validRegex)) {
                    callback(`Enter valid ${rule.label} address`);
                } else {
                    callback()
                }
            } else {
                if (!moment(value, "DD/MM/YYYY", true).isValid()) {
                    callback(`Enter valid ${rule.label}`);
                } else {
                    callback();
                }
            }
        } else {
            callback()
        }
    }


    render() {
        const {
            children,
            formItemLayout,
            item
        } = this.props;
        let rules = [];

        let FIL = {};

        if (!formItemLayout) {
            FIL = {
                labelCol: {
                    xs: {span: 24},
                    sm: {span: 8},
                    md: {span: 8},
                },
                wrapperCol: {
                    xs: {span: 24},
                    sm: {span: 16},
                    md: {span: 12},
                },
            };
        } else {
            FIL = formItemLayout;
        }

        if (item.key == 'email' || item.key == 'dateOfBirth') {
            rules.push({
                required: item.required,
                label: item.label,
                key: item.key,
                validator: this.checkEmailValidation
            });
        } else {
            if (item.required) {
                rules.push({
                    required: true,
                    message: item.requiredMessage
                        ? item.requiredMessage
                        : `${item.label} a Mandatory Field`,
                });
            }
        }

        /*     if (item.label === undefined) {
                 item.label = S(item.key).humanize().titleCase().s;
             }*/

        let customEvent = {};

        let inputProps = {};

        if (!!item.placeholder) inputProps.placeholder = item.placeholder;

        if (!!item.options) {
            inputProps.options = item.options;
        } else {
            // inputProps.options = ['Choose']
        }

        if (!!item.type) inputProps.type = item.type;
        if (!!item.mode) inputProps.mode = item.mode;
        if (!!item.rows) inputProps.rows = item.rows;
        if (!!item.keyAccessor) inputProps.keyAccessor = item.keyAccessor;
        if (!!item.valueAccessor) inputProps.valueAccessor = item.valueAccessor;
        if (!!item.id) inputProps.id = item.id;
        return (
            <div style={styles.mainDiv}>
                <React.Fragment key={item.key}>
                    {!item.hidden && (
                        <React.Fragment>
                            <FormItem
                                {...FIL}
                                key={item.key}
                                style={item.itemStyle}
                                label={item.label}
                                extra={item.extra}>
                                <SimpleFormElement
                                    item={item}
                                    {...inputProps}
                                    allowClear={item.allowClear}
                                    disabledDate={item.disabledDate}
                                />

                            </FormItem>


                        </React.Fragment>
                    )}
                </React.Fragment>

                {children}

                {this.props.loading ? (
                    <div style={styles.loadingBox}>{/*<Spin size='large' />*/}</div>
                ) : null}
            </div>
        );
    }
}

export const CustomFormFields = getAllFormFields;
