import React, { useState } from "react";
import Slider from "react-slick";

const Agents = () => {
    var settings = {
        dots: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        centerMode: true,
        centerPadding: "0px",
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };

    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        contactNumber: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Here you would typically send formData to your API
    };

    return (
        <>
            {/* Begin page content */}
            <main className="flex-shrink-0">
                <section className="about-banner">
                    <div className="spacing-60">
                        <div className="container">
                            <div className="col-lg-10 mx-auto col-12">
                                <div className="row row-gap-3 align-items-center">
                                    <div className="col-lg-6 col-12">
                                        <h2 className="m-0">
                                            Empowering Travel Agents to Deliver Exceptional Experiences
                                        </h2>
                                    </div>
                                    <div className="col-lg-5 ms-auto col-12">
                                        <p className="m-0">
                                            <b className="d-block fw-500">Your Gateway to Memorable Journeys</b>
                                            Access the Best Deals and Unmatched Flight Options at Your Fingertips
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="form-section">
                    <img
                        src="/images/agent-banner.jpg"
                        className="banner-login"
                    />
                    <div className="container">
                        <div className="row row-gap-3">
                            <div className="col-lg-5 col-md-6 col-12 me-auto">
                                <div className="card rounded-0 shadow-none p-3 border-0 p-lg-5">
                                    <div className="main-head mb-3">
                                        <h5 style={{ color: "#d71a21" }}>
                                            Yes, I'm Interested, Sign me up!
                                        </h5>
                                        <p className="m-0">Login here to your account</p>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Full Name"
                                                name="fullName"
                                                value={formData.fullName}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="Email Address"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <input
                                                type="tel"
                                                className="form-control"
                                                placeholder="Contact Number"
                                                name="contactNumber"
                                                value={formData.contactNumber}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <button type="submit" className="btn btn-primary">
                                            REGISTER
                                        </button>
                                        <div className="mt-2 d-flex flex-wrap gap-3">
                                            <p className="m-0">
                                                Our support executives will help you activate your
                                                account and guide you along the way.
                                            </p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="note-banner pt-lg-4 pt-3">
                    <div className="container">
                        <h3 className="m-0">
                            Partner with X Travel World to
                            <br className="d-none d-lg-block" />
                            Access Unmatched B2B Flight Booking Solutions
                        </h3>
                    </div>
                </section>

                <section className="our-values spacing-60 pb-0">
                    <div className="container">
                        <div className="head text-center mb-5">
                            <h3>Why Partner with X Travel World?</h3>
                        </div>

                        <div className="row row-gap-3">
                            <div className="col-lg-4 col-12">
                                <div className="card border-0 text-center p-lg-5 p-4">
                                    <span>icon</span>
                                    <p className="m-0">
                                        Access to competitive flight fares and exclusive deals
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-12">
                                <div className="card border-0 text-center p-lg-5 p-4">
                                    <span>icon</span>
                                    <p className="m-0">24/7 customer support for agents</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-12">
                                <div className="card border-0 text-center p-lg-5 p-4">
                                    <span>icon</span>
                                    <p className="m-0">
                                        Fast and secure booking platform with advanced features
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-12">
                                <div className="card border-0 text-center p-lg-5 p-4">
                                    <span>icon</span>
                                    <p className="m-0">Instant ticketing and confirmation</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-12">
                                <div className="card border-0 text-center p-lg-5 p-4">
                                    <span>icon</span>
                                    <p className="m-0">
                                        Dedicated account managers for personalized assistance
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-12">
                                <div className="card border-0 text-center p-lg-5 p-4">
                                    <span>icon</span>
                                    <p className="m-0">
                                        Access to competitive flight fares and exclusive deals
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="download-application spacing-60">
                    <div className="container">
                        <div className="card pt-3 pt-lg-5">
                            <div className="col-lg-11 col-11 mx-auto">
                                <div className="row row-gap-3">
                                    <div className="col-lg-7 me-auto col-12">
                                        <div className="content">
                                            <h3 className="text-white mb-2 mb-lg-4">
                                                Download Our Mobile Application
                                            </h3>
                                            <p>
                                                Book the flight tickets with the huge discounts.
                                                <br /> Refer friends and get generous bonuses
                                                from their orders.
                                            </p>
                                            <p>Enter your phone number to get download link</p>
                                            <div className="flex-box">
                                                <form>
                                                    <div className="mb-2">
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            placeholder="Enter Mobile Number"
                                                            id="exampleInputNumber"
                                                        />
                                                    </div>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-secondary"
                                                    >
                                                        Send Download Link
                                                    </button>
                                                </form>
                                                <span className="or">or</span>
                                                <div className="download-btns">
                                                    <a href="#">
                                                        <img
                                                            src="../images/app-store.jpg"
                                                            alt="app-store"
                                                        />
                                                    </a>
                                                    <a href="#">
                                                        <img
                                                            src="../images/google-play.jpg"
                                                            alt="google-play"
                                                        />
                                                    </a>
                                                </div>
                                                <div className="scanner">
                                                    <img
                                                        src="../images/scaner.jpg"
                                                        alt="scanner"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 d-lg-block d-none">
                                        <img
                                            src="../images/mobile.png"
                                            alt="mobile"
                                            className="w-100"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="review">
                    <div className="container">
                        <div className="row row-gap-3">
                            <div className="col-lg-4 col-12">
                                <div className="star-box mb-lg-4 mb-3">
                                    <ul>
                                        <li><img src="../images/star.png" alt="star"/></li>
                                        <li><img src="../images/star.png" alt="star"/></li>
                                        <li><img src="../images/star.png" alt="star"/></li>
                                        <li><img src="../images/star.png" alt="star"/></li>
                                        <li><img src="../images/star.png" alt="star"/></li>
                                    </ul>
                                </div>
                                <div className="head text-start">
                                    <h3 className="mb-2">What our Client Reviews?</h3>
                                    <p className="m-0">We take pride in delivering exceptional service and memorable
                                        travel experiences. Here's what our clients have to say about their journeys
                                        with us:</p>
                                </div>
                            </div>
                            <div className="col-lg-8 col-12">
                                <div className="review-slide">
                                    <Slider {...settings}>
                                        <div>
                                            <div className="review-box">
                  <span className="mb-3">
                    <ul>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                    </ul>
                  </span>
                                                <h4 className={'text-white'}>Smooth Booking Experience!</h4>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laboriosam
                                                    labore error quasi. Tempora adipisci omnis aperiam quae debitis iure
                                                    nobis.</p>
                                                <a href="#">Read more...</a>
                                                <h3 className={'text-white'}>Arun K.</h3>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="review-box">
                  <span className="mb-3">
                    <ul>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                    </ul>
                  </span>
                                                <h4 className={'text-white'}>Smooth Booking Experience!</h4>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laboriosam
                                                    labore error quasi. Tempora adipisci omnis aperiam quae debitis iure
                                                    nobis.</p>
                                                <a href="#">Read more...</a>
                                                <h3 className={'text-white'}>Arun K.</h3>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="review-box">
                  <span className="mb-3">
                    <ul>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                    </ul>
                  </span>
                                                <h4 className={'text-white'}>Smooth Booking Experience!</h4>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laboriosam
                                                    labore error quasi. Tempora adipisci omnis aperiam quae debitis iure
                                                    nobis.</p>
                                                <a href="#">Read more...</a>
                                                <h3 className={'text-white'}>Arun K.</h3>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="review-box">
                  <span className="mb-3">
                    <ul>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                    </ul>
                  </span>
                                                <h4 className={'text-white'}>Smooth Booking Experience!</h4>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laboriosam
                                                    labore error quasi. Tempora adipisci omnis aperiam quae debitis iure
                                                    nobis.</p>
                                                <a href="#">Read more...</a>
                                                <h3 className={'text-white'}>Arun K.</h3>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="review-box">
                  <span className="mb-3">
                    <ul>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                    </ul>
                  </span>
                                                <h4 className={'text-white'}>Smooth Booking Experience!</h4>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laboriosam
                                                    labore error quasi. Tempora adipisci omnis aperiam quae debitis iure
                                                    nobis.</p>
                                                <a href="#">Read more...</a>
                                                <h3 className={'text-white'}>Arun K.</h3>
                                            </div>
                                        </div>
                                    </Slider>

                                </div>
                                <div className="text-end" style={{marginTop: '19px'}}>
                                    <a href="#" className="btn text-decoration-none text-white fw-500 btn-primary">View
                                        All</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="bonus spacing-60">
                    <div className="container">
                        <section className="note-banner">
                            <h3 className="m-0 text-uppercase rounded-2"><a href="register">Register Now!</a></h3>
                        </section>
                    </div>
                </section>
            </main>
        </>
    );
};

export default Agents;
