import React from "react"

const Checkbox = (props) => {
    let {name, value = [], options = [], onChange = () => null, defaultChecked = ''} = props;

    let keyAccessor = props.keyAccessor ? props.keyAccessor : val => val.id ? val.id : val._id;
    let valueAccessor = props.valueAccessor ? props.valueAccessor : val => val.display;

    return (
        <>
            <ul>
                {options && options.length ? options.map((item) => {
                    let valueField = typeof item === "object" ? valueAccessor(item) : item;
                    let keyField = typeof item === "object" ? keyAccessor(item) : item;
                    return (
                        <li key={item}>
                            <input className="form-check-input"
                                   type="radio"
                                   defaultChecked={defaultChecked}
                                   name={name}
                                   value={keyField}
                                   checked={value === keyField}
                                   onChange={() => onChange(keyField)}
                                   id={name}/>
                            <span/>
                            <label>{valueField}</label>
                        </li>
                    )
                }) : null}
            </ul>
        </>
    )
}
export default Checkbox
