import moment from "moment";
import _ from "lodash"

export const flightTypeOption = [
    {display: "One-Way", id: "One-Way"},
    {display: "Round-Trip", id: "Round-Trip"},
    {display: "Multi-Destination", id: "Multi-Destination"},
]
export const JourneyTypeOptions = [
    {display: "Economy", id: "1"},
    {display: "Premium Economy", id: "2"},
    {display: "Business", id: "3"},
    {display: "First", id: "4"},
]
export const JourneyObject = {
    "1": "Economy",
    "2": "Premium Economy",
    "3": "Business",
    "4": "First"
}
export const adultOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
export const childrenOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8]
export const dateWithDay = (date) => {
    return moment(date).format('DD MMM, ddd')
}
export const displayTime = (date) => {
    return moment(date).format('hh:mm A')
}

export const displayDate = (date) => {
    return moment(date).format('MMM Do, ddd hh:mm A')
}
export const displayDateOnly = (date) => {
    return moment(date).format('MMM Do, ddd')
}
export const minToHours = (minutes) => {
    const hours = Math.floor(minutes / 60); // Calculate the whole hours
    const remainingMinutes = minutes % 60; // Calculate the remaining minutes
    return `${hours}hr ${remainingMinutes}min`; // Format the output
}
export const titleArr = ['Mr', 'Ms', 'Mrs', 'Mstr', 'Miss']
export const genderArr = [
    {name: "Male", value: 1},
    {name: "Female", value: 2},
]
export const monthArr = [
    {name: 'January', key: 1},
    {name: 'February', key: 2},
    {name: 'March', key: 3},
    {name: 'April', key: 4},
    {name: 'May', key: 5},
    {name: 'June', key: 6},
    {name: 'July', key: 7},
    {name: 'August', key: 8},
    {name: 'September', key: 9},
    {name: 'October', key: 10},
    {name: 'November', key: 11},
    {name: 'December', key: 12}
]

export const daysArr = () => {
    let arr = []
    _.times(31, (item) => {
        arr.push(item + 1)
    })
    return arr;
}
export const yearArr = (type = "adult") => {
    let arr = []
    let lessYear = type == "adult" ? 10 : 1
    let endYear = moment().subtract(lessYear, 'year').year()
    if (type == "child") {
        _.times(12, (item) => {
            arr.push(endYear - item);
        })
    } else {
        _.times(110, (item) => {
            arr.push(endYear - item);
        })
    }
    return arr;
}

export const passengerTypeObject = {
    "1": "Adult",
    "2": "Child",
    "3": "",
}
export const fixed2Digit = (value) => {
    if (value) {
        return parseFloat(parseFloat(value).toFixed(2))
    } else {
        return 0
    }
}
