import axios from 'axios'
import _ from 'lodash'
import {apiUrl, apiUrl2} from './settings'

export const authAxios = axios.create({
    baseURL: apiUrl
})
export let customAxios = axios.create({
    baseURL: apiUrl2
})

customAxios.interceptors.response.use(function (response) {
    if (response.status == 200) {
    }
    return response
}, function (error) {
    handleErr(error)
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
})


export const getToken = () => {
    return ({
        'headers': {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH',
            'Access-Control-Allow-Credentials': true,
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'X-Requested-With': 'XMLHttpRequest',
            'crossdomain': true,
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
            "withCredentials": true
        }
    })
}

export const getUserToken = async () => {
    return new Promise(async (next, error) => {
        try {
            // console.log("token is", await AsyncStorage.getItem('token'))
            next(localStorage.getItem('token'))
        } catch (e) {
            next(null)
        }
    })
}

export const getUserData = async () => {
    return new Promise((next, error) => {
        try {
            next(localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null)
        } catch (e) {
            next(null)
        }
    })
}

export const handleErr = (err) => {
    try {
        if (err.response.status === 401) {
            let dontAskOtpForIds = _.clone(localStorage.getItem('dontAskOtpForIds'))
            localStorage.clear()
            // localStorage.setItem('dontAskOtpForIds', dontAskOtpForIds)
            window.location.reload()
            // console.log('this should be here ')
        }
    } catch (e) {
    }
}
export const getLoginUser = () => {
    return localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
}

export default {}
