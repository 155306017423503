import React, {useEffect, useState} from 'react'
import Select from "rc-select";
import debounce from 'lodash/debounce'
import _ from 'lodash'
import {flightsCodeFxn} from "../containers/flightBooking/actions";

const {Option} = Select

class CityTypeheadComponent extends React.Component {
    state = {
        arrList: [],
        value: [],
        fetching: false,
        city: '',
        cityId: ''
    }

    constructor(props) {
        super(props)
    }

    _getUserId = () => {
        let {cityId} = this.props
        if (cityId) {
            this.setState({
                cityId
            }, () => {
                this.fetchFlights()
            })
        }
    }

    componentDidMount() {
        this._getUserId()
    }

    fetchFlights = async (city) => {
        let {cityId} = this.state
        this.setState({arrList: [], fetching: true})
        let obj = {
            results: 30,
            count: 30,
            cityName: city,
            regExFilters: ['cityName']
        }
        if (cityId && !city) {
            obj._id = cityId
        }

        let {data} = await flightsCodeFxn(obj);
        let dataNew = []
        if (data && data.length) {
            _.each(data, (item) => {
                dataNew.push({
                    text: `${item.airPortName} (${item.airportCode}) ${item.cityName}, ${item.countryName}`,
                    value: item.airportCode
                })
            })
        }
        let newObj = {
            arrList: dataNew
        }
        if (cityId && !city && dataNew && dataNew.length) {
            let currentAgent = dataNew[0]
            newObj.city = {
                key: currentAgent.value,
                label: currentAgent.text
            }
        }
        this.setState(newObj)
    }

    handleChange = value => {
        let {onSelect} = this.props
        this.setState({
            city: value,
            cityId: value && value.key ? value.key : '',
            data: [],
            fetching: false
        })
        onSelect((value && value.key) || '')
    }

    render() {
        const {fetching, arrList, companyName, required = false} = this.state
        let {customStyle = '', placeholder = 'Select City'} = this.props
        return (
            <Select
                labelInValue
                value={companyName || undefined}
                className={customStyle}
                style={{width: "100%"}}
                placeholder={placeholder}
                allowClear={true}
                notFoundContent={fetching ? null : null}
                filterOption={false}
                showSearch={true}
                onSearch={debounce(this.fetchFlights, 500)}
                onChange={this.handleChange}>
                {arrList.map(d => (
                    <Option key={d.value} value={d.value}>{d.text}</Option>
                ))}
            </Select>
        )
    }
}

export default CityTypeheadComponent
