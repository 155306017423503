import React, {useEffect, useState} from "react";
import Slider from "react-slick";
import RegisterBanner from "../../components/pageComponents/registerBanner";
import {useDispatch} from "react-redux";
import {singleTicketFxn} from "../flightBooking/actions";

const TicketResponse = () => {
    let [response, setResponse] = useState({})
    let dispatch = useDispatch()
    useEffect(() => {
        loadData()
    }, [])
    const loadData = async () => {
        let ticketId = window.location.pathname.split('/').pop();
        let {data, success} = await dispatch(singleTicketFxn({ticketId}))
        if (data && data.response) {
            setResponse(data.response)
        }
    }
    return (
        <>
            <section className="about-banner">
                <div className="spacing-60">
                    <div className="container">
                        <div className="col-lg-12 col-12 align-center">
                            <h2 className="m-0">Ticket Response</h2>
                        </div>
                    </div>
                </div>
            </section>

            <section className="agent-with-us">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-12">
                            <div className="head text-start">
                                <p>
                                    {response['Response'] == 1 ?
                                        <></> :
                                        <></>}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <RegisterBanner/>
        </>
    );
};

export default TicketResponse;
