import React, {Component} from 'react'

class InputComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        let {
            name = '', value = '', placeholder = "", key = '', onChange = () => {
            }
        } = this.props
        return (
            <input
                key={key}
                name={name}
                placeholder={placeholder || key}
                className={'form-control custom-form-control'}
                value={value}
                onChange={({target}) => {
                    onChange(target.value)
                }}
            />
        )
    }
}

export default InputComponent

