import React from "react";

const ContactUs = () => {
    return (
        <>
            <main className="flex-shrink-0">
                <section className="about-banner">
                    <div className="spacing-60">
                        <div className="container">
                            <div className="col-lg-10 mx-auto col-12">
                                <div className="row row-gap-3 align-items-center">
                                    <div className="col-lg-6 col-12">
                                        <h2 className="m-0">Get in touch with us!</h2>
                                    </div>
                                    <div className="col-lg-5 ms-auto col-12">
                                        <p className="m-0">At X Travel World, we're here to assist you with all your
                                            travel needs, from flight bookings to customer support and partnership
                                            inquiries. Whether you're an existing partner or looking to explore our
                                            services, we're just a message away!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src="../images/contact-us-banner.jpg" className="w-100"/>
                </section>

                <div className="contact-form spacing-60 pb-0">
                    <div className="container">
                        <div className="row row-gap-3">
                            <div className="col-lg-6 col-12">
                                <div className="head mb-3 text-start">
                                    <h3 className="mb-2">Contact us!</h3>
                                    <p className="m-0">Any questions? We would be happy to help you!</p>
                                </div>
                                <ul className="details">
                                    <li>
                                        <img src="../images/phone.png" alt="Phone"/> 1800-102-0340
                                    </li>
                                    <li>
                                        <img src="../images/message.png" alt="Message"/> enquiry@xtravelworld.com
                                    </li>
                                    <li>
                                        <img src="../images/location.png" alt="Location"/>
                                        SCO 178, 2nd floor, Sector 38C, Chandigarh, 160036
                                        <br/>140306 (INDIA)

                                    </li>
                                </ul>

                                <section className="download-application mt-lg-5 mt-3">
                                    <div className="card m-0 p-0">
                                        <div className="content">
                                            <h3 className="text-white mb-2 mb-lg-4">Download Our Mobile Application</h3>
                                            <p>Book the flight tickets with huge discounts. <br/> Refer friends and
                                                get generous bonuses from their orders.</p>
                                            <p>Enter your phone number to get a download link</p>
                                            <div className="flex-box">
                                                <form>
                                                    <div className="mb-2">
                                                        <input type="tel" className="form-control"
                                                               placeholder="Enter Mobile Number"
                                                               id="exampleInputNumber" required/>
                                                    </div>
                                                    <button type="submit" className="btn btn-secondary">Send Download
                                                        Link
                                                    </button>
                                                </form>
                                                <span className="or">or</span>
                                                <div className="download-btns">
                                                    <a href="#"><img src="../images/app-store.jpg" alt="App Store"/></a>
                                                    <a href="#"><img src="../images/google-play.jpg"
                                                                     alt="Google Play"/></a>
                                                </div>
                                                <div className="scaner">
                                                    <img src="../images/scaner.jpg" alt="QR Code Scanner"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div className="col-lg-6 col-12">
                                <section className="form-section p-0">
                                    <div className="card rounded-0 shadow-none p-0 border-0 m-0">
                                        <form>
                                            <div className="row row-gap-3">
                                                <div className="col-lg-6 col-12">
                                                    <label className="form-label">First Name</label>
                                                    <input type="text" className="form-control"
                                                           placeholder="Your First Name" required/>
                                                </div>
                                                <div className="col-lg-6 col-12">
                                                    <label className="form-label">Last Name</label>
                                                    <input type="text" className="form-control"
                                                           placeholder="Your Last Name" required/>
                                                </div>
                                                <div className="col-lg-12 col-12">
                                                    <label className="form-label">Email</label>
                                                    <input type="email" className="form-control"
                                                           placeholder="Enter Email" required/>
                                                </div>
                                                <div className="col-lg-12 col-12">
                                                    <label className="form-label">Phone Number</label>
                                                    <input type="tel" className="form-control"
                                                           placeholder="Enter Your Number" required/>
                                                </div>
                                                <div className="col-12">
                                                    <label className="form-label">Message</label>
                                                    <textarea className="form-control"
                                                              placeholder="Type your message here..."
                                                              required/>
                                                </div>
                                            </div>
                                            <button type="submit" className="btn btn-primary mt-4">Submit</button>
                                        </form>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="note-banner pt-lg-4 pt-3">
                    <div className="container">
                        <h3 className="m-0">It's Time to make your Decision for Business Growth! <br
                            className="d-none d-lg-block"/><a href="register"
                                                              className="d-block text-decoration-none text-white mt-1">REGISTER
                            NOW!</a></h3>
                    </div>
                </section>

                <section className="download-application spacing-60">
                    <div className="container">
                        <div className="card pt-3 pt-lg-5">
                            <div className="col-lg-11 col-11 mx-auto">
                                <div className="row row-gap-3">
                                    <div className="col-lg-7 me-auto col-12">
                                        <div className="content">
                                            <h3 className="text-white mb-2 mb-lg-4">Download Our Mobile Application</h3>
                                            <p>Book the flight tickets with huge discounts. <br/> Refer friends and
                                                get generous bonuses from their orders.</p>
                                            <p>Enter your phone number to get a download link</p>
                                            <div className="flex-box">
                                                <form>
                                                    <div className="mb-2">
                                                        <input type="tel" className="form-control"
                                                               placeholder="Enter Mobile Number"
                                                               id="exampleInputNumber" required/>
                                                    </div>
                                                    <button type="submit" className="btn btn-secondary">Send Download
                                                        Link
                                                    </button>
                                                </form>
                                                <span className="or">or</span>
                                                <div className="download-btns">
                                                    <a href="#"><img src="../images/app-store.jpg" alt="App Store"/></a>
                                                    <a href="#"><img src="../images/google-play.jpg"
                                                                     alt="Google Play"/></a>
                                                </div>
                                                <div className="scaner">
                                                    <img src="../images/scaner.jpg" alt="QR Code Scanner"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 d-lg-block d-none">
                                        <img src="../images/mobile.png" alt="Mobile" className="w-100"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

export default ContactUs;
