import React from "react";
import Slider from "react-slick";

const AboutUs = () => {
    var settings = {
        dots: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        centerMode: true,
        centerPadding: "0px",
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };
    return (
        <>
            {/* Begin page content */}
            <main className="flex-shrink-0">
                <section className="about-banner">
                    <div className="spacing-60">
                        <div className="container">
                            <div className="col-lg-10 mx-auto col-12">
                                <div className="row align-items-center">
                                    <div className="col-lg-5 me-auto col-12">
                                        <h2 className="m-0">Your Trusted Partner in B2B Flight Bookings</h2>
                                    </div>
                                    <div className="col-lg-6 col-12">
                                        <p className="m-0">Connecting Travel Agents with Seamless Flight Booking
                                            Solutions Worldwide</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src="../images/about-banner.jpg" className="w-100"/>
                </section>

                <section className="note-banner pt-lg-4 pt-3">
                    <div className="container">
                        <h3 className="m-0">Partner with X Travel World to <br /> Access Unmatched B2B Flight Booking Solutions</h3>
                    </div>
                </section>

                <section className="agent-with-us">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-12">
                                <div id="container" className="text-center">
                                    <div className="item">
                                        <img src="../images/logo-blue.png" alt="Logo"/>
                                    </div>
                                    <div className="circle"></div>
                                    <div className="circle" style={{ animationDelay: "0s" }}></div>
                                    <div className="circle" style={{ animationDelay: "1s" }}></div>
                                    <div className="circle" style={{ animationDelay: "2s" }}></div>
                                    <div className="circle" style={{ animationDelay: "3s" }}></div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div className="head text-start">
                                    <h5 className="mb-3 fw-600">Our journey in Revolutionizing B2B Travel</h5>
                                    <p className="m-0">Founded with a vision to simplify travel for agents worldwide, X Travel World has grown into a leading B2B flight booking platform. Our mission is to empower travel agents by providing them with the most comprehensive and reliable tools for flight bookings.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="bonus spacing-60 pt-lg-0">
                    <div className="container">
                        <div className="box position-relative text-center py-lg-5 py-3">
                            <img src="https://images.pexels.com/photos/7683897/pexels-photo-7683897.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Travel" />
                            <div className="overlay"></div>
                            <div className="row row-gap-3">
                                <div className="col-lg-8 mx-auto col-md-12">
                                    <div className="position-relative z-1">
                                        <h5 className={'white'}>Simplifying Travel with Innovative Solutions</h5>
                                        <p className="m-0">At X Travel World, we offer an advanced flight booking platform that provides agents with real-time access to thousands of flights globally. Our partnerships with major airlines ensure you get the best deals and instant confirmations.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="our-values">
                    <div className="container">
                        <div className="head text-center mb-5">
                            <h3>Our Values</h3>
                        </div>

                        <div className="row row-gap-3">
                            <div className="col-lg-4 col-12">
                                <div className="card border-0 text-center p-lg-5 p-4">
                                    <span>icon</span>
                                    <h5>Integrity</h5>
                                    <p className="m-0">Building trust with transparent and ethical practices.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-12">
                                <div className="card border-0 text-center p-lg-5 p-4">
                                    <span>icon</span>
                                    <h5>Innovation</h5>
                                    <p className="m-0">Constantly evolving to provide cutting-edge solutions.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-12">
                                <div className="card border-0 text-center p-lg-5 p-4">
                                    <span>icon</span>
                                    <h5>Customer-Centric</h5>
                                    <p className="m-0">Prioritizing the needs and success of our partners.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="download-application spacing-60">
                    <div className="container">
                        <div className="card pt-3 pt-lg-5">
                            <div className="col-lg-11 col-11 mx-auto">
                                <div className="row row-gap-3">
                                    <div className="col-lg-7 me-auto col-12">
                                        <div className="content">
                                            <h3 className="text-white mb-2 mb-lg-4">Download Our Mobile Application</h3>
                                            <p>Book the flight tickets with huge discounts. <br /> Refer friends and get generous bonuses from their orders.</p>
                                            <p>Enter your phone number to get the download link</p>
                                            <div className="flex-box">
                                                <form>
                                                    <div className="mb-2">
                                                        <input type="number" className="form-control" placeholder="Enter Mobile Number" id="exampleInputNumber" />
                                                    </div>
                                                    <button type="submit" className="btn btn-secondary">Send Download Link</button>
                                                </form>
                                                <span className="or">or</span>
                                                <div className="download-btns">
                                                    <a href="#"><img src="../images/app-store.jpg" alt="app-store"/></a>
                                                    <a href="#"><img src="../images/google-play.jpg" alt="google-play"/></a>
                                                </div>
                                                <div className="scanner">
                                                    <img src="../images/scanner.jpg" alt="QR Code"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 d-lg-block d-none">
                                        <img src="../images/mobile.png" alt="mobile" className="w-100"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="review">
                    <div className="container">
                        <div className="row row-gap-3">
                            <div className="col-lg-4 col-12">
                                <div className="star-box mb-lg-4 mb-3">
                                    <ul>
                                        <li><img src="../images/star.png" alt="star"/></li>
                                        <li><img src="../images/star.png" alt="star"/></li>
                                        <li><img src="../images/star.png" alt="star"/></li>
                                        <li><img src="../images/star.png" alt="star"/></li>
                                        <li><img src="../images/star.png" alt="star"/></li>
                                    </ul>
                                </div>
                                <div className="head text-start">
                                    <h3 className="mb-2">What our Client Reviews?</h3>
                                    <p className="m-0">We take pride in delivering exceptional service and memorable
                                        travel experiences. Here's what our clients have to say about their journeys
                                        with us:</p>
                                </div>
                            </div>
                            <div className="col-lg-8 col-12">
                                <div className="review-slide">
                                    <Slider {...settings}>
                                        <div>
                                            <div className="review-box">
                  <span className="mb-3">
                    <ul>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                    </ul>
                  </span>
                                                <h4 className={'text-white'}>Smooth Booking Experience!</h4>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laboriosam
                                                    labore error quasi. Tempora adipisci omnis aperiam quae debitis iure
                                                    nobis.</p>
                                                <a href="#">Read more...</a>
                                                <h3 className={'text-white'}>Arun K.</h3>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="review-box">
                  <span className="mb-3">
                    <ul>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                    </ul>
                  </span>
                                                <h4 className={'text-white'}>Smooth Booking Experience!</h4>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laboriosam
                                                    labore error quasi. Tempora adipisci omnis aperiam quae debitis iure
                                                    nobis.</p>
                                                <a href="#">Read more...</a>
                                                <h3 className={'text-white'}>Arun K.</h3>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="review-box">
                  <span className="mb-3">
                    <ul>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                    </ul>
                  </span>
                                                <h4 className={'text-white'}>Smooth Booking Experience!</h4>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laboriosam
                                                    labore error quasi. Tempora adipisci omnis aperiam quae debitis iure
                                                    nobis.</p>
                                                <a href="#">Read more...</a>
                                                <h3 className={'text-white'}>Arun K.</h3>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="review-box">
                  <span className="mb-3">
                    <ul>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                    </ul>
                  </span>
                                                <h4 className={'text-white'}>Smooth Booking Experience!</h4>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laboriosam
                                                    labore error quasi. Tempora adipisci omnis aperiam quae debitis iure
                                                    nobis.</p>
                                                <a href="#">Read more...</a>
                                                <h3 className={'text-white'}>Arun K.</h3>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="review-box">
                  <span className="mb-3">
                    <ul>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                    </ul>
                  </span>
                                                <h4 className={'text-white'}>Smooth Booking Experience!</h4>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laboriosam
                                                    labore error quasi. Tempora adipisci omnis aperiam quae debitis iure
                                                    nobis.</p>
                                                <a href="#">Read more...</a>
                                                <h3 className={'text-white'}>Arun K.</h3>
                                            </div>
                                        </div>
                                    </Slider>

                                </div>
                                <div className="text-end" style={{marginTop: '19px'}}>
                                    <a href="#" className="btn text-decoration-none text-white fw-500 btn-primary">View
                                        All</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="bonus spacing-60">
                    <div className="container">
                        <section className="note-banner">
                            <h3 className="m-0 text-uppercase rounded-2"><a href="register">Register Now!</a></h3>
                        </section>
                    </div>
                </section>
            </main>
        </>
    );
};

export default AboutUs;
