import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import FlightInfoBox from "../findFlight/flightInfoBox";
import {GetEachFormFields} from "../../components/utils/formUtils";
import {CustomFormFields} from "../../components/utils/customFormUtils";
import {
    daysArr,
    genderArr,
    monthArr,
    passengerTypeObject,
    titleArr,
    yearArr,
    fixed2Digit
} from "../../components/utils/appUtils";
import _ from "lodash"
import {flightsFearQuoteFxn, lccFlightTicketFxn} from "../flightBooking/actions";
import {Icon, Input, notification, Select} from "../../components/Elements";
import RegisterBanner from "../../components/pageComponents/registerBanner";
import {useNavigate} from "react-router-dom";


const SummaryRow = (props) => {
    let {title, published, offered, showIcon = true} = props;
    return (
        <>
            <div className={'summary-grid'}>
                <div>{title}</div>
                <div>
                    {published ?
                        <>
                            {showIcon ? <Icon name={'rupee'}/> : ""}
                            {published}
                        </> : null}

                </div>
                {offered !== undefined ? <div>
                    {showIcon ? <Icon name={'rupee'}/> : ""}
                    {offered}
                </div> : null}
            </div>
        </>
    )
}
const FlightSummary = (props) => {
    let {flightData} = props;
    let {FareBreakdown: fareBreakdown} = flightData;
    let taxEvents = {
        getSingleTax: (data, taxName) => {
            let findD = _.find(data, (item) => {
                return item.key == taxName
            })
            if (findD) {
                return findD.value
            }
        },
        getOtherTax: (data) => {
            let totalTax = 0;
            _.each(data, (item) => {
                if (!['YQTax', 'YR'].includes(item.key)) {
                    totalTax = totalTax + item.value
                }
            })
            return totalTax;
        },
    }
    return (
        <>
            <div className="detail-box">
                <h4>Fare Summary</h4>
                <SummaryRow published={'Published'} offered={'Offered'} showIcon={false}/>
                {fareBreakdown && fareBreakdown.length ? fareBreakdown.map((item) => {
                    let totalAmount = item['BaseFare'] + item['Tax'];
                    return (
                        <>
                            <SummaryRow title={<b>{passengerTypeObject[item['PassengerType']]}</b>}
                                        published={fixed2Digit(item['BaseFare'] / 2)}
                                        offered={fixed2Digit(item['BaseFare'] / 2)}
                                        showIcon={false}
                            />
                            <SummaryRow title={'QT Tax'}
                                        published={fixed2Digit(taxEvents.getOtherTax(item['TaxBreakUp']) / 2)}
                                        offered={fixed2Digit(taxEvents.getOtherTax(item['TaxBreakUp']) / 2)}/>
                            <SummaryRow title={'YQ Tax'}
                                        published={fixed2Digit(taxEvents.getSingleTax(item['TaxBreakUp'], 'YQTax') / 2)}
                                        offered={fixed2Digit(taxEvents.getSingleTax(item['TaxBreakUp'], 'YQTax') / 2)}/>
                            <SummaryRow title={'YR Tax'}
                                        published={fixed2Digit(taxEvents.getSingleTax(item['TaxBreakUp'], 'YR') / 2)}
                                        offered={fixed2Digit(taxEvents.getSingleTax(item['TaxBreakUp'], 'YR') / 2)}/>
                            <SummaryRow title={'T. Fee and S.Charges:'}
                                        published={0}
                                        offered={0}/>
                            <hr/>
                            <SummaryRow title={<b>Total</b>}
                                        published={<b>{fixed2Digit(totalAmount / 2)}</b>}
                                        offered={<strong>{fixed2Digit(totalAmount / 2)}</strong>}/>
                            <hr/>
                            <br/>
                        </>
                    )
                }) : null}

                {/* {fareBreakdown && fareBreakdown.length ? fareBreakdown.map((item) => {
                    return(
                        <>

                        </>
                    )
                }):null}*/}


                <ul>
                    <li>Total Pub. Fare <span><Icon name={'rupee'}/> {flightData.Fare['PublishedFare']}</span></li>
                    {/*   <li>Taxes & Fees <span>Rs 1,53,256.00</span></li>
                    <li>Food Snack & Drink <span>Rs 1,53,256.00</span></li>
                    <li>Amount to Pay <span>Rs 1,53,256.00</span></li>*/}
                </ul>
                {/*       <button className="btn btn-primary w-100" type="button">Pay <Icon
                    name={'rupee'}/> {flightData.Fare['PublishedFare']}</button>*/}
            </div>
            {/*   <div className="detail-box mt-3">
                <h4>X Travel CB Points <span className="d-block mt-1">1 Point = Rs 1.00</span></h4>
                <ul>
                    <li>Available <span>47.00</span></li>
                </ul>
                <input
                    type="text"
                    placeholder="Enter Amount to Redeem"
                    className="form-control"

                />
                <button className="btn btn-primary w-100" type="button">Redeem</button>
            </div>*/}
        </>
    )
}
const SinglePassengerComponent = (props) => {
    let {data, index, passengerType, onChange, key} = props;
    const passengerField = [
        {
            key: "title",
            type: "select",
            placeholder: "Choose Title *",
            required: true,
            options: titleArr,
            span: "col-md-2",
            keyAccessor: (option) => option,
            valueAccessor: (option) => option,
        },
        {
            key: "firstName",
            placeholder: "First Name *",
            required: true,
            span: "col-md-5",
        },
        {
            key: "lastName",
            placeholder: "Last Name *",
            required: true,
            span: "col-md-5",
        },
        {
            key: "gender",
            type: "select",
            placeholder: "Gender *",
            required: true,
            options: genderArr,
            span: "col-md-6",
            keyAccessor: (option) => option.value,
            valueAccessor: (option) => option.name,
        },
        {
            key: "day",
            type: "select",
            placeholder: "Day *",
            required: true,
            span: "col-md-2",
            showSearch: true,
            options: daysArr(),
        },
        {
            key: "month",
            type: "select",
            placeholder: "Month *",
            required: true,
            span: "col-md-2",
            showSearch: true,
            options: monthArr,
            keyAccessor: (option) => option.name,
            valueAccessor: (option) => option.value,
        },
        {
            key: "year",
            type: "select",
            placeholder: "Year *",
            required: true,
            showSearch: true,
            span: "col-md-2",
            options: yearArr(passengerType),
        },

        {
            key: "email",
            placeholder: "Email address",
            span: "col-md-6",
            type: "email"
        },
        {
            key: "contactNo",
            placeholder: "Contact No",
            span: "col-md-6",
        }
    ]
    return (
        <>
            <div className="track-flights" key={key}>
                <ul className={'passenger-title-border'}>
                    <li className="head-accordion">
                        <h6>{passengerType} {index + 1}</h6>
                    </li>
                </ul>
                <div className="row row-gap-3">
                    {passengerField.map((item) => {
                        let id = `${item.key}-${index}-${passengerType}`
                        let required = false
                        if (['email', 'contactNo'].includes(item.key)) {
                            item.placeholder = item.placeholder + (passengerType == "adult" && index == 0 ? " *" : "")
                            if (passengerType == "adult" && index == 0) {
                                required = true;
                            }
                        } else {
                            required = true;
                        }

                        return (
                            <div className={item.span}>
                                <CustomFormFields item={{
                                    ...item, passengerType: passengerType,
                                    // required: {required},
                                    id: id, name: id,
                                    value: data && data[item.key] ? data[item.key] : "",
                                    onChange: (value) => {
                                        onChange({[item.key]: value})
                                    }
                                }}/>
                            </div>
                        )
                    })}
                </div>

            </div>
        </>
    )
}
const PassengerComponent = (props) => {
    let initState = {}
    let {data = initState, index, passengerType, onChange, key} = props;

    return (
        <>
            <div className="track-flights" key={key}>
                <ul className={'passenger-title-border'}>
                    <li className="head-accordion">
                        <h6>{passengerType} {index + 1}</h6>
                    </li>
                </ul>
                <div className="row row-gap-3">
                    <div className={'col-md-2'}>
                        <Select
                            options={titleArr}
                            value={data.title}
                            keyAccessor={(x) => x}
                            valueAccessor={(x) => x}
                            onChange={(value) => {
                                console.log(value);
                                onChange({title: value})
                            }}/>
                    </div>

                    <div className={'col-md-5'}>
                        <Input
                            placeholder={'First name'}
                            value={data.firstName}
                            onChange={(value) => {
                                onChange({firstName: value})
                            }}/>
                    </div>
                    <div className={'col-md-5'}>
                        <Input
                            placeholder={'Last Name'}
                            value={data.lastName}
                            onChange={(value) => {
                                onChange({lastName: value})
                            }}/>
                    </div>
                    <div className={'col-md-6'}>
                        <Select
                            name={'Gender'}
                            label={'Gender'}
                            options={genderArr}
                            value={data.gender}
                            keyAccessor={(x) => x.value}
                            valueAccessor={(x) => x.name}
                            onChange={(value) => {
                                onChange({gender: value})
                            }}/>
                    </div>
                    <div className={'col-md-6'}>
                        <div className={'custom-date-grid w-100'}>
                            <Select
                                options={daysArr()}
                                value={data.day}
                                keyAccessor={(x) => x}
                                valueAccessor={(x) => x}
                                onChange={(value) => {
                                    onChange({day: value})
                                }}/>
                            <Select
                                options={monthArr}
                                value={data.month}
                                keyAccessor={(x) => x.value}
                                valueAccessor={(x) => x.name}
                                onChange={(value) => {
                                    onChange({month: value})
                                }}/>
                            <Select
                                options={yearArr(passengerType)}
                                value={data.year}
                                keyAccessor={(x) => x}
                                valueAccessor={(x) => x}
                                onChange={(value) => {
                                    onChange({year: value})
                                }}/>
                        </div>
                    </div>
                    <div className={'col-md-6'}>
                        <Input
                            placeholder={'Email'}
                            value={data.email}
                            onChange={(value) => {
                                onChange({email: value})
                            }}/>
                    </div>
                    <div className={'col-md-5'}>
                        <Input
                            placeholder={'Contact No'}
                            value={data.contactNo}
                            onChange={(value) => {
                                onChange({contactNo: value})
                            }}/>
                    </div>
                </div>

            </div>
        </>
    )
}

const ReviewBookingComponent = (props) => {
    let {adultPassengers, childPassengers} = props;
    let singleRow = (item) => {
        return (
            <tr>
                <td>{item.title}</td>
                <td>{item.firstName}</td>
                <td>{item.lastName}</td>
                <td>{item.gender == "2" ? "Female" : "Male"}</td>
                <td>{item.day} {item.month}, {item.year}</td>
                <td>{item.email}</td>
                <td>{item.contactNo}</td>
            </tr>
        )
    }
    return (
        <>
            <table className={'table table-bordered table-striped'}>
                <tr>
                    <th>Title</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Gender</th>
                    <th>DOB</th>
                    <th>Email</th>
                    <th>Contact No</th>
                </tr>
                {adultPassengers && adultPassengers.map((item) => {
                    return singleRow(item)
                })}
                {childPassengers && childPassengers.map((item) => {
                    return singleRow(item)
                })}
            </table>
        </>
    )
}

const Checkout = () => {
    const {flightObj, traceId, selectedFlight} = useSelector((state) => ({
        flightObj: state.flightsReducer.flightObj,
        traceId: state.flightsReducer.traceId,
        selectedFlight: state.flightsReducer.selectedFlight,
    }))
    const navigate = useNavigate();

    let dispatch = useDispatch()
    const [activeTab, setActiveTab] = useState(1)
    const [fareObject, setFareObject] = useState({})

    const [childPassengers, setChildPassengers] = useState([]);
    const [adultPassengers, setAdultPassengers] = useState([]);
    const [redeemAmount, setRedeemAmount] = useState('');
    const [selectedPayment, setSelectedPayment] = useState('');

    const handleRedeemChange = (e) => {
        setRedeemAmount(e.target.value);
    };
    const handlePaymentChange = (event) => {
        setSelectedPayment(event.target.value);
    };

    useEffect(() => {
        events.fareQuote();
    }, [])

    const events = {
        updateAdultPassenger: (data, index) => {
            let cloneD = _.clone(adultPassengers);
            cloneD[index] = {...cloneD[index], ...data}
            setAdultPassengers(cloneD)
        },
        updateChildPassenger: (data, index) => {
            let cloneD = _.clone(childPassengers);
            cloneD[index] = {...cloneD[index], ...data}
            setChildPassengers(cloneD)
        },
        fareQuote: async () => {
            let obj = {
                traceId: traceId,
                resultIndex: selectedFlight['ResultIndex'],
            }
            let {data, success} = await dispatch(flightsFearQuoteFxn(obj))
            if (success) {
                setFareObject(data)
            }
        },
        addPassenger: () => {
            setActiveTab(2)
        },
        sendForReview: (e) => {
            e.preventDefault();
            let allPassenger = [...adultPassengers, ...childPassengers]
            let findE = _.find(allPassenger, (item) => {
                return !item.title && !item.firstName && !item.lastName && !item.gender && !item.day && !item.month && !item.year
            })
            if (findE) {
                notification.error({message: "Please enter all required fields."})
                return
            }
            let firstPass = adultPassengers && adultPassengers.length ? adultPassengers[0] : {}
            if (!firstPass.email) {
                notification.error({message: "Please enter email address."})
                return
            }
            if (!firstPass.contactNo) {
                notification.error({message: "Please enter contact no."})
                return
            }
            setActiveTab(3)
        },
        bookTicket: async () => {
            let allPassenger = [];
            if (adultPassengers && adultPassengers.length) {
                _.each(adultPassengers, (item) => {
                    allPassenger.push({...item, passengerType: 1})
                })
            }
            if (childPassengers && childPassengers.length) {
                _.each(childPassengers, (item) => {
                    allPassenger.push({...item, passengerType: 2})
                })
            }

            let obj = {
                traceId: traceId,
                resultIndex: selectedFlight['ResultIndex'],
                allPassenger,
                fareQuote: fareObject
            }
            let {ticketId} = await dispatch(lccFlightTicketFxn(obj));
            navigate(`/response/${ticketId}`)
        }
    }


    return (
        <>

            <div className="checkout-box">
                <div className="flight-booking bg-white spacing-40">
                    <div className="container position-relative">
                        <div className="col-lg-12">
                            <ul className="nav nav-tabs border-0 gap-3" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link  rounded-pill border-0 ${activeTab == 1 ? "active" : ""}`}
                                        id="flight-itinerary-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#flight-itinerary"
                                        type="button"
                                        role="tab"
                                        aria-controls="flight-itinerary"
                                        disabled={activeTab !== 1}
                                        aria-selected="true">
                                        Flight Itinerary
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link  rounded-pill border-0 ${activeTab == 2 ? "active" : ""}`}
                                        id="passenger-details-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#passenger-details"
                                        type="button"
                                        role="tab"
                                        disabled={activeTab !== 2}
                                        aria-controls="passenger-details"
                                        aria-selected="false">
                                        Passenger Details
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link  rounded-pill border-0 ${activeTab == 3 ? "active" : ""}`}
                                        id="review-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#review"
                                        type="button"
                                        role="tab"
                                        disabled={activeTab !== 3}
                                        aria-controls="review"
                                        aria-selected="false"
                                    >
                                        Review
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link  rounded-pill border-0 ${activeTab == 4 ? "active" : ""}`}
                                        id="payment-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#payment"
                                        type="button"
                                        role="tab"
                                        disabled={activeTab !== 4}
                                        aria-controls="payment"
                                        aria-selected="false">
                                        Payment
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="spacing-40 container">
                    <div className="row row-gap-3">
                        <div className="col-lg-9">
                            <div className="tab-content" id="myTabContent">
                                <div className={`tab-pane fade ${activeTab == 1 ? "show active" : ""}`}
                                     id="flight-itinerary" role="tabpanel"
                                     aria-labelledby="flight-itinerary-tab">
                                    <div className="head d-flex gap-3 align-items-center mb-3">
                                        <span>Visa, Passport & Health Regulations</span>
                                        <button
                                            className="btn p-0 bg-transparent ms-auto d-flex align-items-center gap-2"
                                            onClick={() => window.history.back()}> {/* Use a function to go back */}
                                            Back to Search
                                            <img src="images2/arrow-up.png" width="24" alt="Back to Search"/>
                                        </button>
                                    </div>
                                    <div className="find-flight-main">
                                        <div className="flight-destimation-time">
                                            <div className="row row-gap-3 align-items-center">
                                                <div className="col-lg-4">
                                                    <div className="d-flex align-items-center"
                                                         style={{gap: '30px'}}>
                                                        <h6 className="m-0 white">Delhi</h6>
                                                        <span><img src="images2/plane.png" width="22"
                                                                   alt="plane"/></span>
                                                        <h6 className="m-0 white">Toronto</h6>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <p className="m-0">on Friday, October, 4th, 2024</p>
                                                </div>
                                                <div className="col-lg-4">
                                                    <p className="m-0">21hr 10min</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-flight-track">
                                            <FlightInfoBox data={selectedFlight}/>

                                            <p className="note">
                                                Require to change Plane - Layover Time: 3h 15m
                                            </p>
                                        </div>

                                        {/*   <div className="flight-accordion-details mt-3">
                                            <div className="accordion" id="accordionPanelsStayOpenExample">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header d-flex align-items-center"
                                                        id="panelsStayOpen-headingOne">
                                                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                            <li className="nav-item" role="presentation">
                                                                <button
                                                                    className="nav-link active"
                                                                    id="fare-rules-tab"
                                                                    data-bs-toggle="tab"
                                                                    data-bs-target="#fare-rules-tab-pane"
                                                                    type="button"
                                                                    role="tab"
                                                                    aria-controls="fare-rules-tab-pane"
                                                                    aria-selected="true">
                                                                    Fare Rules
                                                                </button>
                                                            </li>
                                                        </ul>
                                                        <a href="#"
                                                           data-bs-toggle="collapse"
                                                           data-bs-target="#panelsStayOpen-collapseOne"
                                                           aria-expanded="true"
                                                           aria-controls="panelsStayOpen-collapseOne"
                                                           className="btn p-0 ms-auto">
                                                            <img src="images2/arrow-up.png" alt="arrow-up"
                                                                 width="28"/>
                                                        </a>
                                                    </h2>
                                                    <div
                                                        id="panelsStayOpen-collapseOne"
                                                        className="accordion-collapse collapse show"
                                                        aria-labelledby="panelsStayOpen-headingOne"
                                                    >
                                                        <div className="accordion-body">
                                                            <div className="tab-content" id="myTabContent">
                                                                <div
                                                                    className="tab-pane fade active show"
                                                                    id="fare-rules-tab-pane"
                                                                    role="tabpanel"
                                                                    aria-labelledby="fare-rules-tab"
                                                                    tabIndex="0"
                                                                >
                                                                    <div className="flight-custom-table">
                                                                        <div className="flight-destimation-time">
                                                                            <div
                                                                                className="row row-gap-3 align-items-center">
                                                                                <div className="col-lg-4">
                                                                                    <div
                                                                                        className="d-flex align-items-center"
                                                                                        style={{gap: '30px'}}>
                                                                                        <h6 className="m-0 white">Delhi</h6>
                                                                                        <span><img
                                                                                            src="images2/plane.png"
                                                                                            width="22"
                                                                                            alt="plane"/></span>
                                                                                        <h6 className="m-0 white">Toronto</h6>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <p className="m-0">on Friday,
                                                                                        October,
                                                                                        4th, 2024</p>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <p className="m-0">21hr
                                                                                        10min</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="content-box">
                                                                            <ul>
                                                                                <small style={{fontSize: '14px'}}
                                                                                       className="mb-3 d-block">
                                                                                    * To view charges, click on the
                                                                                    below
                                                                                    fee sections.
                                                                                </small>
                                                                                <li><span>Time Frame</span> <span>As Per Airline</span>
                                                                                </li>
                                                                                <li><span>Cancellation Fee</span>
                                                                                    <span>Airline Cancellation Charges + Taxes + Surcharges</span>
                                                                                </li>
                                                                                <li><span>Date Change Fee</span>
                                                                                    <span>Before Departure: Rs.14,011.54 Allowed</span>
                                                                                </li>
                                                                                <li><span>No Show Fee</span> <span>As Per Airline: Fees As Per Airline Rule + Surcharges</span>
                                                                                </li>
                                                                                <li><span>Seat Chargeable Fee</span>
                                                                                    <span>As Per Airline</span>
                                                                                </li>
                                                                            </ul>
                                                                        </div>

                                                                        <div
                                                                            className="rules d-flex flex-wrap flex-lg-nowrap gap-3">
                                                                            <ul className="w-100">
                                                                                <li>Lorem ipsum dolor sit amet
                                                                                    consectetur
                                                                                    adipisicing elit.
                                                                                </li>
                                                                                <li>Lorem ipsum dolor sit amet.</li>
                                                                                <li>Lorem ipsum dolor sit amet.</li>
                                                                                <li>Lorem ipsum dolor sit amet
                                                                                    consectetur
                                                                                    adipisicing elit.
                                                                                </li>
                                                                            </ul>
                                                                            <button
                                                                                className="btn btn-primary text-nowrap mt-auto">
                                                                                Detailed Rules
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>*/}

                                        <button className="btn btn-primary w-100 mt-3"
                                                onClick={() => events.addPassenger()}>ADD
                                            PASSENGERS
                                        </button>
                                    </div>
                                </div>


                                <div className={`tab-pane fade ${activeTab == 2 ? "show active" : ""}`}
                                     id="passenger-details" role="tabpanel"
                                     aria-labelledby="passenger-details-tab">
                                    <div className="head d-flex gap-3 align-items-center mb-3">
                                        <span>Select from History</span>
                                        <button
                                            className="btn p-0 bg-transparent ms-auto d-flex align-items-center gap-2">
                                            Back to Search <img src="../images2/arrow-up.png" width="24" alt="arrow"/>
                                        </button>
                                    </div>
                                    <form onSubmit={events.sendForReview}>
                                        <div className="passanger-details">
                                            <div className={'custom-form-box'}>
                                                {_.times(flightObj.adultCount, (adItem) => {
                                                    return (
                                                        <>
                                                            <SinglePassengerComponent
                                                                data={adultPassengers[adItem]}
                                                                index={adItem}
                                                                onChange={(data) => {
                                                                    events.updateAdultPassenger(data, adItem)
                                                                }}
                                                                passengerType={'adult'} key={`adult-${adItem}`}/>
                                                        </>
                                                    )
                                                })}
                                                {_.times(flightObj.childCount, (adItem) => {
                                                    return (
                                                        <>
                                                            <SinglePassengerComponent
                                                                data={childPassengers[adItem]}
                                                                index={adItem}
                                                                onChange={(data) => {
                                                                    events.updateChildPassenger(data, adItem)
                                                                }}
                                                                passengerType={'child'} key={`child-${adItem}`}/>
                                                        </>
                                                    )
                                                })}

                                            </div>
                                        </div>
                                        <button className="btn btn-primary w-100 mt-3">Review Booking
                                        </button>
                                    </form>
                                </div>


                                <div className={`tab-pane fade ${activeTab == 3 ? "show active" : ""}`} id="review"
                                     role="tabpanel"
                                     aria-labelledby="review-tab">
                                    <ReviewBookingComponent childPassengers={childPassengers}
                                                            adultPassengers={adultPassengers}/>
                                    <button className="btn btn-primary w-100 mt-3" onClick={events.bookTicket}>Ticket
                                    </button>
                                </div>

                                <div className={`tab-pane fade ${activeTab == 4 ? "show active" : ""}`} id="payment"
                                     role="tabpanel"
                                     aria-labelledby="payment-tab">
                                    <div className="head d-flex gap-3 align-items-center mb-3">
                                        <button
                                            className="btn p-0 bg-transparent ms-auto d-flex align-items-center gap-2">
                                            Back to Search <img src="../images2/arrow-up.png" width="24" alt="Back"/>
                                        </button>
                                    </div>

                                    <div className="passanger-details">
                                        <div className="title">
                                            <h5 className="w-100 justify-content-start">Payments</h5>
                                        </div>

                                        <div className="payment-method">
                                            <h4>Choose Payment Method</h4>

                                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link active" id="deposit-tab"
                                                            data-bs-toggle="tab" data-bs-target="#deposit-tab-pane"
                                                            type="button" role="tab"
                                                            aria-controls="deposit-tab-pane"
                                                            aria-selected="true">Deposit
                                                    </button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="card-tab" data-bs-toggle="tab"
                                                            data-bs-target="#card-tab-pane" type="button" role="tab"
                                                            aria-controls="card-tab-pane"
                                                            aria-selected="false">Credit/Debit Card
                                                    </button>
                                                </li>
                                            </ul>
                                            <div className="tab-content" id="myTabContent">
                                                <div className="tab-pane fade show active" id="deposit-tab-pane"
                                                     role="tabpanel"
                                                     aria-labelledby="deposit-tab" tabIndex="0">
                                                    Deposit Content
                                                </div>
                                                <div className="tab-pane fade" id="card-tab-pane" role="tabpanel"
                                                     aria-labelledby="card-tab" tabIndex="0">
                                                    <div className="choose-mode">
                                                        <ul>
                                                            <li>
                                                                <input type="radio" name="choose" value="mastercard"
                                                                       onChange={handlePaymentChange}
                                                                       checked={selectedPayment === 'mastercard'}/>
                                                                <label><img src="images2/master-card.png"
                                                                            alt="Mastercard"/></label>
                                                            </li>
                                                            <li>
                                                                <input type="radio" name="choose" value="paypal"
                                                                       onChange={handlePaymentChange}
                                                                       checked={selectedPayment === 'paypal'}/>
                                                                <label><img src="images2/pay-pal.png"
                                                                            alt="PayPal"/></label>
                                                            </li>
                                                            <li>
                                                                <input type="radio" name="choose" value="visa"
                                                                       onChange={handlePaymentChange}
                                                                       checked={selectedPayment === 'visa'}/>
                                                                <label><img src="images2/visa.png"
                                                                            alt="Visa"/></label>
                                                            </li>
                                                            <li>
                                                                <input type="radio" name="choose" value="wallet"
                                                                       onChange={handlePaymentChange}
                                                                       checked={selectedPayment === 'wallet'}/>
                                                                <label>Wallet</label>
                                                            </li>
                                                            <li>
                                                                <input type="radio" name="choose" value="upi"
                                                                       onChange={handlePaymentChange}
                                                                       checked={selectedPayment === 'upi'}/>
                                                                <label>UPI</label>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <form>
                                                        <div className="row row-gap-3">
                                                            <div className="col-lg-6">
                                                                <input type="number" className="form-control"
                                                                       placeholder="Card Number"/>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <input type="text" className="form-control"
                                                                       placeholder="Expiration Date MM/YY"/>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <input type="number" className="form-control"
                                                                       placeholder="CVV"/>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <input type="text" className="form-control"
                                                                       placeholder="Name on Card"/>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className="terms-check d-flex flex-wrap gap-2 gap-lg-5 flex-lg-nowrap mt-3 align-items-center">
                                            <div className="d-flex gap-2 text-nowrap align-items-center">
                                                <input type="checkbox"/>
                                                <span><img src="../images2/design-check.png" alt="Check"/></span>
                                                I accept <a href="#">terms & conditions</a>
                                            </div>
                                            <button className="btn btn-primary w-100 mx-auto">PROCEED TO PAY
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            {fareObject && fareObject.ResultIndex ? <FlightSummary flightData={fareObject}/> : null}
                        </div>
                    </div>
                </div>
            </div>

            <RegisterBanner/>
        </>
    );
}

export default Checkout;
