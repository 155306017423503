import React from "react"

const Reviews = () => {
    return (
        <>
            <section className="review spacing-60 pt-0">
                <div className="container">
                    <div className="col-lg-10 col-12 mx-auto">
                        <div className="row row-gap-3 align-items-center">
                            <div className="col-lg-4 col-12">
                                <div className="star-box mb-lg-4 mb-3">
                                    <ul>
                                        <li>
                                            <img src="images2/star.png" alt="star"/>
                                        </li>
                                        <li>
                                            <img src="images2/star.png" alt="star"/>
                                        </li>
                                        <li>
                                            <img src="images2/star.png" alt="star"/>
                                        </li>
                                        <li>
                                            <img src="images2/star.png" alt="star"/>
                                        </li>
                                        <li>
                                            <img src="images2/star.png" alt="star"/>
                                        </li>
                                    </ul>
                                </div>
                                <div className="head text-start">
                                    <h3 className="mb-2">What our Client Reviews?</h3>
                                    <p className="m-0">
                                        We take pride in delivering exceptional service and memorable
                                        travel experiences. Here's what our clients have to say about
                                        their journeys with us:
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-8 col-12">
                                <div className="review-slide">
                                    <div>
                                        <div className="review-box">
                  <span className="mb-3">
                    <ul>
                      <li>
                        <img src="images2/star.png" alt="star"/>
                      </li>
                      <li>
                        <img src="images2/star.png" alt="star"/>
                      </li>
                      <li>
                        <img src="images2/star.png" alt="star"/>
                      </li>
                      <li>
                        <img src="images2/star.png" alt="star"/>
                      </li>
                      <li>
                        <img src="images2/star.png" alt="star"/>
                      </li>
                    </ul>
                  </span>
                                            <h4>Smooth Booking Experience!</h4>
                                            <p>
                                                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                                                Laboriosam labore error quasi. Tempora adipisci omnis
                                                aperiam quae debitis iure nobis.
                                            </p>
                                            <a href="#">Read more...</a>
                                            <h3>Arun K.</h3>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="review-box">
                  <span className="mb-3">
                    <ul>
                      <li>
                        <img src="images2/star.png" alt="star"/>
                      </li>
                      <li>
                        <img src="images2/star.png" alt="star"/>
                      </li>
                      <li>
                        <img src="images2/star.png" alt="star"/>
                      </li>
                      <li>
                        <img src="images2/star.png" alt="star"/>
                      </li>
                      <li>
                        <img src="images2/star.png" alt="star"/>
                      </li>
                    </ul>
                  </span>
                                            <h4>Smooth Booking Experience!</h4>
                                            <p>
                                                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                                                Laboriosam labore error quasi. Tempora adipisci omnis
                                                aperiam quae debitis iure nobis.
                                            </p>
                                            <a href="#">Read more...</a>
                                            <h3>Arun K.</h3>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="review-box">
                  <span className="mb-3">
                    <ul>
                      <li>
                        <img src="images2/star.png" alt="star"/>
                      </li>
                      <li>
                        <img src="images2/star.png" alt="star"/>
                      </li>
                      <li>
                        <img src="images2/star.png" alt="star"/>
                      </li>
                      <li>
                        <img src="images2/star.png" alt="star"/>
                      </li>
                      <li>
                        <img src="images2/star.png" alt="star"/>
                      </li>
                    </ul>
                  </span>
                                            <h4>Smooth Booking Experience!</h4>
                                            <p>
                                                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                                                Laboriosam labore error quasi. Tempora adipisci omnis
                                                aperiam quae debitis iure nobis.
                                            </p>
                                            <a href="#">Read more...</a>
                                            <h3>Arun K.</h3>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="review-box">
                  <span className="mb-3">
                    <ul>
                      <li>
                        <img src="images2/star.png" alt="star"/>
                      </li>
                      <li>
                        <img src="images2/star.png" alt="star"/>
                      </li>
                      <li>
                        <img src="images2/star.png" alt="star"/>
                      </li>
                      <li>
                        <img src="images2/star.png" alt="star"/>
                      </li>
                      <li>
                        <img src="images2/star.png" alt="star"/>
                      </li>
                    </ul>
                  </span>
                                            <h4>Smooth Booking Experience!</h4>
                                            <p>
                                                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                                                Laboriosam labore error quasi. Tempora adipisci omnis
                                                aperiam quae debitis iure nobis.
                                            </p>
                                            <a href="#">Read more...</a>
                                            <h3>Arun K.</h3>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="review-box">
                  <span className="mb-3">
                    <ul>
                      <li>
                        <img src="images2/star.png" alt="star"/>
                      </li>
                      <li>
                        <img src="images2/star.png" alt="star"/>
                      </li>
                      <li>
                        <img src="images2/star.png" alt="star"/>
                      </li>
                      <li>
                        <img src="images2/star.png" alt="star"/>
                      </li>
                      <li>
                        <img src="images2/star.png" alt="star"/>
                      </li>
                    </ul>
                  </span>
                                            <h4>Smooth Booking Experience!</h4>
                                            <p>
                                                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                                                Laboriosam labore error quasi. Tempora adipisci omnis
                                                aperiam quae debitis iure nobis.
                                            </p>
                                            <a href="#">Read more...</a>
                                            <h3>Arun K.</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-end" style={{marginTop: 19}}>
                                    <a
                                        href="#"
                                        className="btn text-decoration-none text-white fw-500 btn-primary"
                                    >
                                        View All
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
export default Reviews
