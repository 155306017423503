import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import RegisterBanner from "../../components/pageComponents/registerBanner";
import {searchFlightFxn} from "../flightBooking/actions";
import {useDispatch, useSelector} from "react-redux";
import SingleFlightCard from "./singleFlightCard";
import {displayDate, displayDateOnly} from "../../components/utils/appUtils";

const FindFlight = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {flightObj} = useSelector((state) => ({
        flightObj: state.flightsReducer.flightObj
    }))
    const [flightList, setFlightList] = useState([])
    const [flightInfo, setFlightInfo] = useState([])

    const [priceRange, setPriceRange] = useState({min: 2500, max: 8500});
    const [departureTime, setDepartureTime] = useState([]);
    const [airline, setAirline] = useState('');
    const [refundability, setRefundability] = useState('');
    const [layoverCity, setLayoverCity] = useState('');
    const [stops, setStops] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedSort, setSelectedSort] = useState('');
    const [showDetails, setShowDetails] = useState(true);
    const [selectedClass, setSelectedClass] = useState('');

    const toggleDetails = () => setShowDetails(prevState => !prevState);

    const handleClassSelect = (e) => setSelectedClass(e.target.value);

    const handleSortChange = (sortOption) => {
        setSelectedSort(sortOption);
    };

    const handleChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const handlePriceChange = (e, type) => {
        const value = parseInt(e.target.value);
        setPriceRange((prev) => (type === 'min' ? {...prev, min: value} : {...prev, max: value}));
    };

    const handleDepartureChange = (time) => {
        setDepartureTime((prev) =>
            prev.includes(time) ? prev.filter(item => item !== time) : [...prev, time]
        );
    };

    const handleAirlineChange = (e) => {
        setAirline(e.target.value);
    };

    const handleRefundabilityChange = (e) => {
        setRefundability(e.target.value);
    };

    const handleLayoverChange = (e) => {
        setLayoverCity(e.target.value);
    };

    const handleStopsChange = (e) => {
        setStops(e.target.value);
    };

    const events = {
        searchFlight: async () => {
            let {data, info} = await dispatch(searchFlightFxn(flightObj));
            console.log(data);
            setFlightList(data)
            setFlightInfo({...info})
        }
    }

    useEffect(() => {
        events.searchFlight()
    }, [])

    return (
        <>
            <div className="find-flight">

                <div className="flight-top-filters spacing-60">
                    <div className="container">
                        <div className="radio-box mb-3">
                            <button className="btn rounded-pill border-0" type="button">
                                <img src="../images2/book-flights.png" alt="Book Flight"/>
                                Book Flight
                            </button>
                            <ul>
                                <li>
                                    <input className="form-check-input" type="radio" name="flexRadioDefault"
                                           id="roundTrip"/>
                                    <label htmlFor="roundTrip">Round-Trip</label>
                                </li>
                                <li>
                                    <input className="form-check-input" type="radio" name="flexRadioDefault"
                                           id="oneWay"/>
                                    <label htmlFor="oneWay">One-Way</label>
                                </li>
                                <li>
                                    <input className="form-check-input" type="radio" name="flexRadioDefault"
                                           id="multiDestination"/>
                                    <label htmlFor="multiDestination">Multi-Destination</label>
                                </li>
                            </ul>
                        </div>
                        <div className="search-filter">
                            <div className="row row-gap-3 align-items-center">
                                <div className="col-lg-4 col-12">
                                    <div className="double-box">
                                        <div className="content">
                                            <span>From</span>
                                            <h5>{flightObj.origin}</h5>
                                            <small></small>
                                        </div>
                                        <div className="divide-box position-relative">
                                            <img src="../images2/shift.png" alt="Shift Icon"/>
                                        </div>
                                        <div className="content" style={{paddingLeft: '20px'}}>
                                            <span>To</span>
                                            <h5>{flightObj.destination}</h5>
                                            <small></small>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12">
                                    <div className="double-box">
                                        <div className="content">
                                            <span>Depart</span>
                                            <h5>{displayDateOnly(flightObj.preferredDepartureTime)}</h5>
                                            {/*<small>Sunday</small>*/}
                                        </div>
                                        <div className="divide-box"></div>
                                        <div className="content">
                                            <span>Arrive</span>
                                            {/*  <h5>7 October 2024</h5>
                                            <small>Wednesday</small>*/}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-12">
                                    <div className="double-box">
                                        <div className="content">
                                            <span>Passenger</span>
                                            <h5>
                                                {flightObj.adultCount ? `${flightObj.adultCount} Adult(s)` : ""}<br/>
                                                {flightObj.childCount ? `${flightObj.childCount} Child(s)` : ""}
                                            </h5>
                                            {/*<small>Business Class</small>*/}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-12">
                                    <button onClick={(e) => {
                                        e.preventDefault(); // Prevent default form submission
                                        setTimeout(() => {
                                            console.log("Redirecting to /find-booking...");
                                            window.location.href = '/find-booking';
                                        }, 500);
                                    }}

                                            type="submit" className="btn btn-primary w-100"

                                    >Modify Search
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="result-flights spacing-60">
                    <div className="container-fluid">
                        <div className="row">


                            <div className="col-lg-3">
                                <div className="main-flight-filter">
                                    <div className="accordion" id="mainFlightFilter">

                                        {/* Price Range */}
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingOne">
                                                <button className="accordion-button bg-white border-0" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#priceRange"
                                                        aria-expanded="true" aria-controls="priceRange">
                                                    Price Range
                                                </button>
                                            </h2>
                                            <div id="priceRange" className="accordion-collapse collapse show"
                                                 aria-labelledby="headingOne" data-bs-parent="#mainFlightFilter">
                                                <div className="accordion-body">
                                                    <div className="price-input-container">
                                                        <div className="slider-container">
                                                            <div className="price-slider">
                                                                {/* Add your slider logic here */}
                                                            </div>
                                                        </div>
                                                        <div className="range-input">
                                                            <input
                                                                type="range"
                                                                className="min-range"
                                                                min="0"
                                                                max="10000"
                                                                value={priceRange.min}
                                                                step="1"
                                                                onChange={(e) => handlePriceChange(e, 'min')}
                                                            />
                                                            <input
                                                                type="range"
                                                                className="max-range"
                                                                min="0"
                                                                max="10000"
                                                                value={priceRange.max}
                                                                step="1"
                                                                onChange={(e) => handlePriceChange(e, 'max')}
                                                            />
                                                        </div>
                                                        <div className="price-input">
                                                            <div className="price-field">
                                                                <span>Min. Price</span>
                                                                <input
                                                                    type="number"
                                                                    className="min-input"
                                                                    value={priceRange.min}
                                                                    onChange={(e) => handlePriceChange(e, 'min')}
                                                                />
                                                            </div>
                                                            <div className="price-field">
                                                                <span>Max. Price</span>
                                                                <input
                                                                    type="number"
                                                                    className="max-input"
                                                                    value={priceRange.max}
                                                                    onChange={(e) => handlePriceChange(e, 'max')}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Flight Schedule */}
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingTwo">
                                                <button className="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#flightSchedule"
                                                        aria-expanded="false" aria-controls="flightSchedule">
                                                    Flight Schedule
                                                </button>
                                            </h2>
                                            <div id="flightSchedule" className="accordion-collapse collapse"
                                                 aria-labelledby="headingTwo" data-bs-parent="#mainFlightFilter">
                                                <div className="accordion-body">
                                                    <div className="departure-radio">
                                                        <ul className="nav nav-pills mb-3" id="pills-tab"
                                                            role="tablist">
                                                            <li className="nav-item" role="presentation">
                                                                <button className="nav-link active"
                                                                        id="pills-departure-tab"
                                                                        data-bs-toggle="pill"
                                                                        data-bs-target="#pills-departure"
                                                                        type="button" role="tab"
                                                                        aria-controls="pills-departure"
                                                                        aria-selected="true">Departure
                                                                </button>
                                                            </li>
                                                            <li className="nav-item" role="presentation">
                                                                <button className="nav-link" id="pills-arrival-tab"
                                                                        data-bs-toggle="pill"
                                                                        data-bs-target="#pills-arrival"
                                                                        type="button" role="tab"
                                                                        aria-controls="pills-arrival"
                                                                        aria-selected="false">Arrival
                                                                </button>
                                                            </li>
                                                        </ul>
                                                        <div className="tab-content" id="pills-tabContent">
                                                            <div className="tab-pane fade show active"
                                                                 id="pills-departure" role="tabpanel"
                                                                 aria-labelledby="pills-departure-tab" tabIndex="0">
                                                                <ul className="p-0 m-0">
                                                                    {['morning', 'noon', 'afternoon', 'night'].map((time, index) => (
                                                                        <li key={index}>
                                                                            <input
                                                                                type="checkbox"
                                                                                name="departureTime"
                                                                                checked={departureTime.includes(time)}
                                                                                onChange={() => handleDepartureChange(time)}
                                                                            />
                                                                            <div className="box">
                                                                                <img
                                                                                    src={`images2/${time}-icon.png`}
                                                                                    alt={`${time}-icon`}/>
                                                                                <small>{time === 'morning' ? '00:00 - 11:59' : time === 'noon' ? '12:00 - 14:59' : time === 'afternoon' ? '15:00 - 17:59' : '18:00 - 23:59'}</small>
                                                                                <h5>{time.charAt(0).toUpperCase() + time.slice(1)}</h5>
                                                                            </div>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                            <div className="tab-pane fade" id="pills-arrival"
                                                                 role="tabpanel" aria-labelledby="pills-arrival-tab"
                                                                 tabIndex="0">
                                                                Lorem ipsum dolor sit amet consectetur adipisicing
                                                                elit.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Airlines */}
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingThree">
                                                <button className="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#airlines"
                                                        aria-expanded="false" aria-controls="airlines">
                                                    Airlines
                                                </button>
                                            </h2>
                                            <div id="airlines" className="accordion-collapse collapse"
                                                 aria-labelledby="headingThree" data-bs-parent="#mainFlightFilter">
                                                <div className="accordion-body">
                                                    <div className="radio-filter">
                                                        <ul>
                                                            {['5000', '3000', '2000', '8000'].map((price, index) => (
                                                                <li key={index}>
                                                                    <input
                                                                        type="radio"
                                                                        name="airline"
                                                                        value={price}
                                                                        checked={airline === price}
                                                                        onChange={handleAirlineChange}
                                                                    />
                                                                    <div className="box">
                                                                        <span></span>
                                                                        <small>Atlanta International Airport</small>
                                                                        <h5>Rs. {price}</h5>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Refundability */}
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingFour">
                                                <button className="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#refundability"
                                                        aria-expanded="false" aria-controls="refundability">
                                                    Refundability
                                                </button>
                                            </h2>
                                            <div id="refundability" className="accordion-collapse collapse"
                                                 aria-labelledby="headingFour" data-bs-parent="#mainFlightFilter">
                                                <div className="accordion-body">
                                                    <div className="radio-filter">
                                                        <ul>
                                                            {['Non Refundable', 'Refundable'].map((option, index) => (
                                                                <li key={index}>
                                                                    <input
                                                                        type="radio"
                                                                        name="refundability"
                                                                        value={option}
                                                                        checked={refundability === option}
                                                                        onChange={handleRefundabilityChange}
                                                                    />
                                                                    <div className="box">
                                                                        <span></span>
                                                                        <h5>{option}</h5>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Layover */}
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingFive">
                                                <button className="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#layover"
                                                        aria-expanded="false" aria-controls="layover">
                                                    Layover City
                                                </button>
                                            </h2>
                                            <div id="layover" className="accordion-collapse collapse"
                                                 aria-labelledby="headingFive" data-bs-parent="#mainFlightFilter">
                                                <div className="accordion-body">
                                                    <div className="radio-filter">
                                                        <ul>
                                                            {['Dubai', 'Singapore', 'Hong Kong'].map((city, index) => (
                                                                <li key={index}>
                                                                    <input
                                                                        type="radio"
                                                                        name="layover"
                                                                        value={city}
                                                                        checked={layoverCity === city}
                                                                        onChange={handleLayoverChange}
                                                                    />
                                                                    <div className="box">
                                                                        <span></span>
                                                                        <small>{city} International Airport</small>
                                                                        <h5>{city}</h5>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Stops */}
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingSix">
                                                <button className="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#stops"
                                                        aria-expanded="false" aria-controls="stops">
                                                    Stops
                                                </button>
                                            </h2>
                                            <div id="stops" className="accordion-collapse collapse"
                                                 aria-labelledby="headingSix" data-bs-parent="#mainFlightFilter">
                                                <div className="accordion-body">
                                                    <div className="radio-filter">
                                                        <ul>
                                                            {['1', '2', '3'].map((stop, index) => (
                                                                <li key={index}>
                                                                    <input
                                                                        type="radio"
                                                                        name="stops"
                                                                        value={stop}
                                                                        checked={stops === stop}
                                                                        onChange={handleStopsChange}
                                                                    />
                                                                    <div className="box">
                                                                        <span></span>
                                                                        <small>{stop} stops</small>
                                                                        <h5>{stop} stops</h5>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="find-flight-main">

                                    <div className="d-flex flex-wrap gap-3 align-items-center mb-3">
                                        <h5 className="m-0">{flightList.length || 0} Available Flights</h5>
                                        <ul className="ms-auto p-0 d-flex gap-2 mb-0 align-items-center">
                                            <li>Share By:</li>
                                            <li>
                                                <img
                                                    src="/images2/whatsapp-icon.png"
                                                    width="28"
                                                    alt="whatsapp-icon"
                                                />
                                            </li>
                                            <li>
                                                <img
                                                    src="/images2/gmail-icon.png"
                                                    width="28"
                                                    alt="gmail-icon"
                                                />
                                            </li>
                                            <li>
                                                <img
                                                    src="/images2/eye-icon.png"
                                                    width="28"
                                                    alt="eye-icon"
                                                />
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="radio-group-box">
                                        <div className="row row-gap-3">
                                            <div className="col-lg-6">
                                                <div className="radio-box">
                                                    <input
                                                        type="radio"
                                                        name="radio-option"
                                                        value="cheapest"
                                                        checked={selectedOption === 'cheapest'}
                                                        onChange={handleChange}
                                                    />
                                                    <label>
                                                        <h6 className="m-0">Cheapest</h6>
                                                        <span>Rs. 50000 - Duration: 23:15</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="radio-box">
                                                    <input
                                                        type="radio"
                                                        name="radio-option"
                                                        value="fastest"
                                                        checked={selectedOption === 'fastest'}
                                                        onChange={handleChange}
                                                    />
                                                    <label>
                                                        <h6 className="m-0">Fastest</h6>
                                                        <span>Rs. 85,961 - Duration: 23:15</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="radio-box">
                                                    <input
                                                        type="radio"
                                                        name="radio-option"
                                                        value="commission"
                                                        checked={selectedOption === 'commission'}
                                                        onChange={handleChange}
                                                    />
                                                    <label>
                                                        <h6 className="m-0">Commission</h6>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="radio-box">
                                                    <input
                                                        type="radio"
                                                        name="radio-option"
                                                        value="max-booked"
                                                        checked={selectedOption === 'max-booked'}
                                                        onChange={handleChange}
                                                    />
                                                    <label>
                                                        <h6 className="m-0">Max. Booked</h6>
                                                        <span>Rs. 85,961 - Duration: 23:15</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="sort-box">
                                        <span>Sort by:</span>
                                        <ul className="m-0 p-0">
                                            <li>
                                                <button
                                                    type="button"
                                                    className="d-flex gap-1"
                                                    onClick={() => handleSortChange('duration')}
                                                >
                                                    Duration
                                                    <img src="/images2/short.png" alt="short-icon"/>
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    type="button"
                                                    className="d-flex gap-1"
                                                    onClick={() => handleSortChange('arrival')}
                                                >
                                                    Arrival
                                                    <img src="/images2/short.png" alt="short-icon"/>
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    type="button"
                                                    className="d-flex gap-1"
                                                    onClick={() => handleSortChange('stops')}
                                                >
                                                    Stops
                                                    <img src="/images2/short.png" alt="short-icon"/>
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    type="button"
                                                    className="d-flex gap-1"
                                                    onClick={() => handleSortChange('price')}
                                                >
                                                    Price
                                                    <img src="/images2/short.png" alt="short-icon"/>
                                                </button>
                                            </li>
                                        </ul>
                                        <button className="btn btn-primary-theme">Select Manual</button>
                                    </div>


                                    {flightList && flightList.length ? flightList.map((item) => {
                                        return (
                                            <SingleFlightCard data={item} flightInfo={flightInfo}/>
                                        )
                                    }) : null}

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <RegisterBanner/>
        </>
    );
};

export default FindFlight;
