import React from "react"

const FlightSlider = () => {
    return (
        <>
            <section className="clients pt-4">
                <div className="container">
                    <div className="clients-box-slide p-4">
                        <div>
                            <img
                                src="images2/Qatar_Airways_Logo.png"
                                alt="Qatar_Airways_Logo"
                            />
                        </div>
                        <div>
                            <img
                                src="images2/Qatar_Airways_Logo.png"
                                alt="Qatar_Airways_Logo"
                            />
                        </div>
                        <div>
                            <img
                                src="images2/Qatar_Airways_Logo.png"
                                alt="Qatar_Airways_Logo"
                            />
                        </div>
                        <div>
                            <img
                                src="images2/Qatar_Airways_Logo.png"
                                alt="Qatar_Airways_Logo"
                            />
                        </div>
                        <div>
                            <img
                                src="images2/Qatar_Airways_Logo.png"
                                alt="Qatar_Airways_Logo"
                            />
                        </div>
                        <div>
                            <img
                                src="images2/Qatar_Airways_Logo.png"
                                alt="Qatar_Airways_Logo"
                            />
                        </div>
                        <div>
                            <img
                                src="images2/Qatar_Airways_Logo.png"
                                alt="Qatar_Airways_Logo"
                            />
                        </div>
                        <div>
                            <img
                                src="images2/Qatar_Airways_Logo.png"
                                alt="Qatar_Airways_Logo"
                            />
                        </div>
                        <div>
                            <img
                                src="images2/Qatar_Airways_Logo.png"
                                alt="Qatar_Airways_Logo"
                            />
                        </div>
                        <div>
                            <img
                                src="images2/Qatar_Airways_Logo.png"
                                alt="Qatar_Airways_Logo"
                            />
                        </div>
                        <div>
                            <img
                                src="images2/Qatar_Airways_Logo.png"
                                alt="Qatar_Airways_Logo"
                            />
                        </div>
                        <div>
                            <img
                                src="images2/Qatar_Airways_Logo.png"
                                alt="Qatar_Airways_Logo"
                            />
                        </div>
                        <div>
                            <img
                                src="images2/Qatar_Airways_Logo.png"
                                alt="Qatar_Airways_Logo"
                            />
                        </div>
                        <div>
                            <img
                                src="images2/Qatar_Airways_Logo.png"
                                alt="Qatar_Airways_Logo"
                            />
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
export default FlightSlider
