import React from "react";

const Dashboard = () => {
    return (
        <main className="flex-shrink-0">
            <div className="dashboard-box d-flex gap-3">
                {/* Sidenav */}
                <div className="sidenav">
                    <button className="slide-btn">
                        <img src="images2/double-back-arrow.png" alt="Back Arrow"/>
                    </button>
                    <ul>
                        <li>
                            <a href="#">
                                <img src="images2/search-white.png" alt="Search"/>
                                <span>User</span>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <img src="images2/search-white.png" alt="Search"/>
                                <span>Markup</span>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <img src="images2/search-white.png" alt="Search"/>
                                <span>Booking Calendar</span>
                            </a>
                        </li>
                        <li>
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                        >
                                            <img src="images2/search-white.png" alt="Search"/>
                                            <span>Payment Passbook</span>
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseOne"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body p-0">
                                            <ul>
                                                <li>
                                                    <a href="#">
                                                        <span>Earning</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" className="active">
                                                        <span>Refunds</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <span>Declines</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <span>Payouts</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <a href="#">
                                <img src="images2/search-white.png" alt="Search"/>
                                <span>Add Deposit</span>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <img src="images2/search-white.png" alt="Search"/>
                                <span>Deposit Requests</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="spacing-80 container">
                    <div className="row row-gap-3">
                        <div className="col-lg-12">
                            <div className="find-flight-main">
                                <div className="passenger-details">
                                    {/* Accordion Section */}
                                    <div className="track-flights p-0 m-0 border-0">
                                        <div className="accordion" id="accordionPanelsStayOpenExample">
                                            {/* Add Markup Accordion */}
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                                    <ul>
                                                        <li className="p-3 head-accordion">
                                                            <h6>Add Markup</h6>
                                                            <a
                                                                href="#"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#panelsStayOpen-collapseOne"
                                                                aria-expanded="true"
                                                                aria-controls="panelsStayOpen-collapseOne"
                                                                className="btn p-0 collapsed"
                                                            >
                                                                <img
                                                                    src="images2/arrow-up.png"
                                                                    alt="Arrow Up"
                                                                    width="28"
                                                                />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </h2>
                                                <div
                                                    id="panelsStayOpen-collapseOne"
                                                    className="accordion-collapse collapse show"
                                                    aria-labelledby="panelsStayOpen-headingOne"
                                                >
                                                    <div className="accordion-body">
                                                        <form>
                                                            <div className="row row-gap-3">
                                                                <div className="col-lg-3">
                                                                    <select name="markup" id="markup-type"
                                                                            className="form-control">
                                                                        <option value="0">Markup Type</option>
                                                                        <option value="1">Option 1</option>
                                                                        <option value="2">Option 2</option>
                                                                        <option value="3">Option 3</option>
                                                                    </select>
                                                                </div>
                                                                <div className="col-lg-3">
                                                                    <select name="airlines" id="airlines"
                                                                            className="form-control">
                                                                        <option value="0">Airlines</option>
                                                                        <option value="1">Airline 1</option>
                                                                        <option value="2">Airline 2</option>
                                                                        <option value="3">Airline 3</option>
                                                                    </select>
                                                                </div>
                                                                <div className="col-lg-3">
                                                                    <select name="pax-types" id="pax-types"
                                                                            className="form-control">
                                                                        <option value="0">Pax Types</option>
                                                                        <option value="1">Pax Types 1</option>
                                                                        <option value="2">Pax Types 2</option>
                                                                        <option value="3">Pax Types 3</option>
                                                                    </select>
                                                                </div>
                                                                <div className="col-lg-3">
                                                                    <select
                                                                        name="amount-type"
                                                                        id="amount-type"
                                                                        className="form-control"
                                                                    >
                                                                        <option value="0">Amount Types</option>
                                                                        <option value="1">Amount Types 1</option>
                                                                        <option value="2">Amount Types 2</option>
                                                                        <option value="3">Amount Types 3</option>
                                                                    </select>
                                                                </div>
                                                                <div className="col-lg-3">
                                                                    <select name="value" id="value"
                                                                            className="form-control">
                                                                        <option value="0">Value</option>
                                                                        <option value="1">Value 1</option>
                                                                        <option value="2">Value 2</option>
                                                                        <option value="3">Value 3</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </form>
                                                        <button className="btn btn-primary w-100 mt-3">ADD MARKUP
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Manage Markup Accordion */}
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                                                    <ul>
                                                        <li className="p-3 head-accordion">
                                                            <h6>Manage Markup</h6>
                                                            <a
                                                                href="#"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#panelsStayOpen-collapseTwo"
                                                                aria-expanded="false"
                                                                aria-controls="panelsStayOpen-collapseTwo"
                                                                className="btn p-0 collapsed"
                                                            >
                                                                <img
                                                                    src="images2/arrow-up.png"
                                                                    alt="Arrow Up"
                                                                    width="28"
                                                                />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </h2>
                                                <div
                                                    id="panelsStayOpen-collapseTwo"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="panelsStayOpen-headingTwo"
                                                >
                                                    <div className="accordion-body">
                                                        <form>
                                                            <table className="table">
                                                                <thead>
                                                                <tr>
                                                                    <th scope="col">
                                                                        Created on{" "}
                                                                        <img src="images2/short.png" width="16"
                                                                             alt=""/>
                                                                    </th>
                                                                    <th scope="col">
                                                                        Markup Type{" "}
                                                                        <img src="images2/short.png" width="16"
                                                                             alt=""/>
                                                                    </th>
                                                                    <th scope="col">
                                                                        Airline{" "}
                                                                        <img src="images2/short.png" width="16"
                                                                             alt=""/>
                                                                    </th>
                                                                    <th scope="col">
                                                                        Pax Types{" "}
                                                                        <img src="images2/short.png" width="16"
                                                                             alt=""/>
                                                                    </th>
                                                                    <th scope="col">
                                                                        Amount Type{" "}
                                                                        <img src="images2/short.png" width="16"
                                                                             alt=""/>
                                                                    </th>
                                                                    <th scope="col">
                                                                        Value{" "}
                                                                        <img src="images2/short.png" width="16"
                                                                             alt=""/>
                                                                    </th>
                                                                    <th scope="col">
                                                                        Action{" "}
                                                                        <img src="images2/short.png" width="16"
                                                                             alt=""/>
                                                                    </th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                <tr>
                                                                    <td>Company Name</td>
                                                                    <td>International</td>
                                                                    <td>All/Air India</td>
                                                                    <td>N/A</td>
                                                                    <td>Fixed</td>
                                                                    <td>50,000</td>
                                                                    <td>
                                                                        <button
                                                                            className="btn py-0"
                                                                            title="Edit"
                                                                            type="button"
                                                                        >
                                                                            <img
                                                                                src="images2/edit.png"
                                                                                alt="Edit"
                                                                                width="20"
                                                                            />
                                                                        </button>
                                                                        <button
                                                                            className="btn py-0"
                                                                            title="Delete"
                                                                            type="button"
                                                                        >
                                                                            <img
                                                                                src="images2/trash.png"
                                                                                alt="Delete"
                                                                                width="20"
                                                                            />
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Manage Users Section */}
                                        <div className="manage-user card-box mt-3 bg-white">
                                            <div className="head p-3">
                                                <div className="box d-flex flex-wrap pb-3 gap-3 align-items-center">
                                                    <h6 className="m-0">Manage Users</h6>
                                                    <div className="search-box ms-auto">
                                                        <input type="text" placeholder="Search by Keywords..."/>
                                                        <button className="btn btn-primary-theme">
                                                            <img
                                                                src="images2/search-white.png"
                                                                alt="Search"
                                                                width="20"
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-3">
                                                <table className="table">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col">
                                                            User Name{" "}
                                                            <img
                                                                src="images2/short.png"
                                                                width="16"
                                                                alt="Sort"
                                                            />
                                                        </th>
                                                        <th scope="col">
                                                            Mobile Number{" "}
                                                            <img
                                                                src="images2/short.png"
                                                                width="16"
                                                                alt="Sort"
                                                            />
                                                        </th>
                                                        <th scope="col">
                                                            Email ID{" "}
                                                            <img
                                                                src="images2/short.png"
                                                                width="16"
                                                                alt="Sort"
                                                            />
                                                        </th>
                                                        <th scope="col">
                                                            Role{" "}
                                                            <img
                                                                src="images2/short.png"
                                                                width="16"
                                                                alt="Sort"
                                                            />
                                                        </th>
                                                        <th scope="col">
                                                            Status{" "}
                                                            <img
                                                                src="images2/short.png"
                                                                width="16"
                                                                alt="Sort"
                                                            />
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>Sunny Mehra</td>
                                                        <td>+91 80540500340</td>
                                                        <td>sunny340@gmail.com</td>
                                                        <td>Counsellor</td>
                                                        <td>
                                                            <div className="form-check form-switch">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="status-switch"
                                                                    checked
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </main>
    );
};

export default Dashboard;
