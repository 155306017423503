import React, {useState} from "react";
import DownloadApplications from "../../../components/pageComponents/downloadApplications";

const ForgotPassword = () => {
    const [emailOrMobile, setEmailOrMobile] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");

    const handleEmailOrMobileChange = (e) => {
        setEmailOrMobile(e.target.value);
    };

    const handleMobileNumberChange = (e) => {
        setMobileNumber(e.target.value);
    };

    const handleEmailOrMobileSubmit = (e) => {
        e.preventDefault();
        // Handle email/mobile submission logic
    };

    const handleMobileSubmit = (e) => {
        e.preventDefault();
        // Handle mobile number submission logic
    };

    return (
        <div>
            <section className="login-module">
                <div className="container">
                    <div className="main-head py-3 py-lg-5">
                        <h3>Get Started Now!</h3>
                        <p className="m-0">Enter Credentials to get started now!</p>
                    </div>
                </div>
            </section>
            <section className="form-section">
                <img src="/images/forgot-banner.jpg" className="banner-login"/>
                <div className="container">
                    <div className="row row-gap-3">
                        <div className="col-lg-7 me-auto">
                            <div className="card rounded-0 shadow-none p-3 border-0 p-lg-5">
                                <div className="head text-center mb-4">
                                    <h3 className="mb-2">Forgot your Password?</h3>
                                    <p className="m-0">Enter your Email or Mobile and we'll help you reset your
                                        password.</p>
                                </div>
                                <form onSubmit={handleEmailOrMobileSubmit}>
                                    <div className="mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Email or Mobile Number to get Instructions"
                                            value={emailOrMobile}
                                            onChange={handleEmailOrMobileChange}
                                        />
                                    </div>
                                    <button type="submit" className="btn btn-primary">Continue</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <DownloadApplications/>
        </div>
    );
};

export default ForgotPassword;
