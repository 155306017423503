import React, {useState} from "react"
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import FlightInfoBox from "./flightInfoBox";
import {flightsFearRuleFxn} from "../flightBooking/actions";
import {Modal} from "../../components/Elements";
import ReactHtmlParser from 'react-html-parser';

const SingleFlightCard = (props) => {
    let {data, flightInfo} = props;
    let {Segments} = data;
    let segmentData = Segments[0]
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let [visible, setVisible] = useState(false)
    let [fareRuleData, setFareRuleData] = useState({})

    const events = {
        bookFlight: async () => {
            let {success, data: fareRuleData} = await dispatch(flightsFearRuleFxn({
                traceId: flightInfo.traceId,
                resultIndex: data.ResultIndex
            }))
            if (success) {
                setVisible(true);
                setFareRuleData(fareRuleData);
            }
        },
        confirmBooking: async () => {
            let {success, data: fareRuleData} = await dispatch(flightsFearRuleFxn({
                traceId: flightInfo.traceId,
                resultIndex: data.ResultIndex
            }))
            if (success) {
                // setVisible(true);

                dispatch({
                    type: "UPDATE_RESULT_INDEX", selectedFlight: {...data, FareBasisCode: fareRuleData.FareBasisCode},
                    traceId: flightInfo.traceId
                })
                navigate('/checkout')

                setFareRuleData(fareRuleData);
            }
        }
    }

    return (
        <>
            <div className="book-flight">
                <div className="row row-gap-3 mb-3">
                    <div className="col-lg-10">

                        {data && data['Source'] ? <FlightInfoBox {...props}/> : null}

                        <div className="class-price">
                            <div className="row">
                                <div className="col-lg-10">
                                    {/* <div className="row row-gap-3">
                                        {['Economy Classic', 'Economy Delight', 'Economy Super'].map((className, index) => (
                                            <div className="col-lg-4" key={index}>
                                                <div className="main-box">
                                                    <input
                                                        type="radio"
                                                        name="class-price"
                                                        value={className}
                                                        checked={selectedClass === className}
                                                        onChange={handleClassSelect}
                                                    />
                                                    <div className="box">
                                                        <span></span>
                                                        <small>{className}</small>
                                                        <h5>{className === 'Economy Classic' ? 'Rs. 4890.00' : className === 'Economy Delight' ? 'Rs. 6732.00' : 'Rs. 93890.00'}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>*/}

                                    <div
                                        className="details d-flex gap-3 flex-wrap align-items-center mt-3">
                                        {/*   <button
                                            className="btn btn-grey text-nowrap d-flex gap-2 align-items-center">View
                                            Details <img src="../images2/short.png"
                                                         width="18" alt=""/></button>*/}

                                        {segmentData && segmentData.length && segmentData[0].NoOfSeatAvailable ?
                                            <p className="m-0" style={{fontSize: '14px'}}>
                                                {/*Flight Arrives after 1 Day(s)*/}
                                                <b className="ms-2"
                                                   style={{color: 'red'}}>
                                                    Seat(s)
                                                    left: {segmentData[0].NoOfSeatAvailable}</b></p> : null}
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    {/*    <button className="btn btn-grey w-100 text-nowrap">Show
                                        Less...
                                    </button>*/}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-2">
                        <div className="d-flex flex-wrap gap-3 h-100">
                            <div className="total-price">
                                <span>Total Price</span>
                                <h5>Rs. {data.Fare && data.Fare.PublishedFare ? data.Fare.PublishedFare : ""}
                                </h5>
                                <small></small>
                                <button
                                    onClick={() => {
                                        events.confirmBooking()
                                    }}
                                    className="btn btn-primary">Book Now
                                </button>
                            </div>
                            {/*     <button className="btn btn-primary-theme mt-auto w-100"
                                    style={{fontSize: '14px'}}>Change Class
                            </button>*/}
                        </div>
                    </div>
                </div>

                {/*  <div className="flight-accordion-details">
                    <div className="accordion" id="accordionPanelsStayOpenExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header d-flex align-items-center"
                                id="panelsStayOpen-headingOne">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    {['Flight Details', 'Fare Details', 'Fare Rules', 'Baggage Information'].map((tab, index) => (
                                        <li className="nav-item" role="presentation"
                                            key={index}>
                                            <button
                                                className={`nav-link ${index === 0 ? 'active' : ''}`}
                                                id={`${tab.toLowerCase().replace(' ', '-')}-tab`}
                                                data-bs-toggle="tab"
                                                data-bs-target={`#${tab.toLowerCase().replace(' ', '-')}-tab-pane`}
                                                type="button"
                                                role="tab"
                                                aria-controls={`${tab.toLowerCase().replace(' ', '-')}-tab-pane`}
                                                aria-selected={index === 0}
                                            >
                                                {tab}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                                <a href="#" onClick={toggleDetails}
                                   className="btn p-0 ms-auto">
                                    <img src="images2/arrow-up.png" alt="arrow-up"
                                         width="28"/>
                                </a>
                            </h2>
                            <div id="panelsStayOpen-collapseOne"
                                 className={`accordion-collapse collapse ${showDetails ? 'show' : ''}`}
                                 aria-labelledby="panelsStayOpen-headingOne">
                                <div className="accordion-body">
                                    <div className="tab-content" id="myTabContent">

                                        <div className="tab-pane fade show active"
                                             id="flight-details-tab-pane" role="tabpanel"
                                             aria-labelledby="flight-details-tab"
                                             tabIndex="0">
                                            <div className="flight-destimation-time">
                                                <div
                                                    className="row row-gap-3 align-items-center">
                                                    <div className="col-lg-4">
                                                        <div
                                                            className="d-flex align-items-center"
                                                            style={{gap: '30px'}}>
                                                            <h6 className="m-0 white">Delhi</h6>
                                                            <span><img
                                                                src="images2/plane.png"
                                                                width="22"
                                                                alt="plane"/></span>
                                                            <h6 className="m-0 white">Toronto</h6>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <p className="m-0">on Friday,
                                                            October, 4th, 2024</p>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <p className="m-0">21hr 10min</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-flight-track">
                                                <div className="track-flights">
                                                    <ul>
                                                        <li className="p-0">
                                                                <span className="logo">
                                                                  <img src="images2/indigo.png" alt=""/>
                                                                </span>
                                                            <h6>Indigo
                                                                <small className="d-block">IG-303,
                                                                    IG-3</small>
                                                                <small className="d-block">1hr
                                                                    15min</small>
                                                            </h6>
                                                            <div
                                                                className="d-flex align-items-center"
                                                                style={{gap: '30px'}}>
                                                                <h6>Oct, 4th, Fri 11:30 AM
                                                                    <small
                                                                        className="d-block">Indira
                                                                        Gandhi
                                                                        International</small>
                                                                    <small
                                                                        className="d-block">Airport,
                                                                        New Delhi, Terminal
                                                                        2</small>
                                                                </h6>
                                                                <span><p>Non-Stop</p><img
                                                                    src="images2/plane.png"
                                                                    alt="plane"/></span>
                                                                <h6>Oct, 4th, Fri 14:45 PM
                                                                    <small
                                                                        className="d-block">Dubai,
                                                                        United Arab
                                                                        Emirates</small>
                                                                    <small
                                                                        className="d-block">Dubai
                                                                        Intl. Airport,
                                                                        Terminal 3</small>
                                                                </h6>
                                                                <h6>3h 45m
                                                                    <small
                                                                        className="d-block">Economy
                                                                        Class</small>
                                                                    <small
                                                                        className="d-block">Non
                                                                        Refundable</small>
                                                                </h6>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <p className="line">Adult: Check-in 2
                                                        Piece (15KG) + Cabin: 1 Piece(8
                                                        KG)</p>
                                                </div>
                                                <p className="note">
                                                    Require to change Plane - Layover Time:
                                                    3h 15m
                                                </p>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>*/}
            </div>
            <br/>


            {visible ?
                <Modal
                    title={'Fare Rules'}
                    onClose={() => setVisible(false)}
                    visible={visible}>
                    <div className={'modal-inner'}>
                        {ReactHtmlParser(fareRuleData.FareRuleDetail)}
                        <br/>
                        <div className={'align-center'}>
                            <a className={'btn btn-primary'} onClick={events.confirmBooking}>
                                Confirm
                            </a>
                        </div>
                    </div>
                </Modal>
                : null}
        </>
    )
}
export default SingleFlightCard
