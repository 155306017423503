import _ from "lodash";
import Select, {Option} from "rc-select";
import "rc-select/assets/index.less";
import React from "react";
import "./index.css";

const children = [];
for (let i = 10; i < 36; i++) {
    children.push(
        <Option key={i.toString(36) + i} disabled={i === 10}>
            label{i}
        </Option>
    );
}

const SelectRc = (props) => {
    let keyAccessor = props.keyAccessor
        ? props.keyAccessor
        : (val) => (val.id ? val.id : val._id);
    let valueAccessor = props.valueAccessor
        ? props.valueAccessor
        : (val) => val.display;
    let {
        onChange = null,
        multiple = false,
        allowClear = false,
        value,
        dropdownRender = null,
        options = [],
        label = "",
        customClass = "",
        showSearch = false,
        disabled = false,
        placeholder = "",
        onSearch = () => {
        }
    } = props;
    const dropdownMenuStyle = {
        maxHeight: 200,
    };

    return (
        <Select
            value={value}
            // animation={this.state.useAnim ? "slide-up" : null}
            name={props.name}
            id={props.id}
            className={customClass}
            choiceTransitionName="rc-select-selection__choice-zoom"
            dropdownMenuStyle={dropdownMenuStyle}
            style={{width: "100%"}}
            disabled={disabled}
            mode={multiple ? "multiple" : "single"}
            showSearch={showSearch}
            // showSearch={true}
            allowClear={allowClear}
            dropdownRender={dropdownRender}
            onSearch={onSearch}
            showAction={["focus", "click"]}
            optionFilterProp="children"
            optionLabelProp="children"
            placeholder={placeholder ? placeholder : `Choose ${label}`}
            onChange={onChange}
            onFocus={() => console.log("focus")}
        >
            {options.map((val, index) => {
                if (typeof val === "object") {
                    return (
                        <Option key={index} value={keyAccessor(val)}>
                            {valueAccessor(val)}
                        </Option>
                    );
                } else {
                    return (
                        <Option key={index} value={val}>
                            {val}
                        </Option>
                    );
                }
            })}
        </Select>
    );
};
const SelectComponent = (props) => {
    let keyAccessor = props.keyAccessor
        ? props.keyAccessor
        : (val) => (val.id ? val.id : val._id);
    let valueAccessor = props.valueAccessor
        ? props.valueAccessor
        : (val) => val.display;
    let {
        onChange = null,
        multiple = false,
        allowClear = false,
        value,
        options = [],
        disabled,
        showSearch,
        onSearch = {},

        placeholder,
        label,
        customClass = "",
        style = {width: "100%"},
    } = props;
    const dropdownMenuStyle = {
        maxHeight: 200,
    };

    return (
        <Select
            className={`rc-select ${customClass}`}
            value={value}
            choiceTransitionName="rc-select-selection__choice-zoom"
            dropdownMenuStyle={dropdownMenuStyle}
            style={style}
            disabled={disabled ? disabled : false}
            multiple={multiple}
            onSearch={onSearch}
            showSearch={showSearch ? showSearch : false}
            allowClear={allowClear}
            showAction={["focus", "click"]}
            optionFilterProp="children"
            optionLabelProp="children"
            placeholder={placeholder ? placeholder : `Choose ${label}`}
            onChange={onChange}
            onFocus={() => console.log("focus")}
        >
            {options.map((val, index) => {
                if (typeof val === "object") {
                    return (
                        <Option key={index} value={keyAccessor(val)}>
                            {valueAccessor(val)}
                        </Option>
                    );
                } else {
                    return (
                        <Option key={index} value={val}>
                            {val}
                        </Option>
                    );
                }
            })}
        </Select>
    );
};
const SelectComponent2 = (props) => {
    let keyAccessor = props.keyAccessor
        ? props.keyAccessor
        : (val) => (val.id ? val.id : val._id);
    let valueAccessor = props.valueAccessor
        ? props.valueAccessor
        : (val) => val.display;
    let {
        onChange = null,
        multiple = false,
        allowClear = false,
        value,
        options = [],
        disabled,
        showSearch,
        placeholder,
        label = "",
        customClass = "",
        onSearch = {},
        dropdownRender = null,
        style = {width: "100%"},
    } = props;
    const dropdownMenuStyle = {
        maxHeight: 200,
    };
    let mode = multiple ? "multiple" : "single";

    return (
        <Select
            className={`rc-select ${customClass}`}
            value={value}
            choiceTransitionName="rc-select-selection__choice-zoom"
            dropdownMenuStyle={dropdownMenuStyle}
            style={style}
            disabled={disabled ? disabled : false}
            //   multiple={multiple}
            onSearch={onSearch}
            mode={mode}
            showSearch={showSearch ? showSearch : false}
            allowClear={allowClear}
            showAction={["focus", "click"]}
            optionFilterProp="children"
            optionLabelProp="children"
            dropdownRender={dropdownRender}
            placeholder={placeholder ? placeholder : `Choose ${label}`}
            onChange={onChange}
            onFocus={() => console.log("focus")}
        >
            {options.map((val, index) => {
                if (typeof val === "object") {
                    return (
                        <Option key={index} value={keyAccessor(val)}>
                            {valueAccessor(val)}
                        </Option>
                    );
                } else {
                    return (
                        <Option key={index} value={val}>
                            {val}
                        </Option>
                    );
                }
            })}
        </Select>
    );
};
const SelectFilterComponent = (props) => {
    let keyAccessor = props.keyAccessor
        ? props.keyAccessor
        : (val) => (val.id ? val.id : val._id);
    let valueAccessor = props.valueAccessor
        ? props.valueAccessor
        : (val) => val.display;
    let {
        onChange = null,
        value = undefined,
        options = [],
        disabled,
        showSearch = false,
        allowClear = false,
        id = null,
        style = {width: "100%"},
    } = props;

    return (
        <div className={`did-floating-select-box`} title={value || ""}>
            <Select
                value={value}
                style={style}
                id={id}
                showSearch={allowClear}
                allowClear={allowClear}
                disabled={disabled ? disabled : false}
                onChange={onChange}
                onFocus={() => console.log("focus")}>
                {options.map((val, index) => {
                    if (typeof val === "object") {
                        return (
                            <Option key={index} value={keyAccessor(val)}>
                                {valueAccessor(val)}
                            </Option>
                        );
                    } else {
                        return (
                            <Option key={index} value={val}>
                                {val}
                            </Option>
                        );
                    }
                })}
            </Select>
        </div>
    );
};

export {SelectComponent, SelectComponent2, SelectRc, SelectFilterComponent};
