const initialState = {
    tokenId: "",
    traceId: "",
    resultIndex: "",
    flightObj: {
        adultCount: "",
        childCount: "",
        infantCount: "",
        directFlight: "",
        oneStopFlight: "",
        journeyType: "",
        preferredAirlines: "",
        origin: "",
        destination: "",
        preferredDepartureTime: "",
        preferredArrivalTime: ""
    },
    selectedFlight: {}
}

export default (state = initialState, action) => {
    switch (action && action.type) {
        case 'SEARCH_FLIGHT':
            return {
                ...state,
                flightObj: action.flightObj
            }

        case 'UPDATE_RESULT_INDEX':
            return {
                ...state,
                traceId: action.traceId,
                selectedFlight: action.selectedFlight,
            }

        default:
            return state
    }
}
