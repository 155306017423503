import React, {useState} from "react"
import DownloadApplications from "../../../components/pageComponents/downloadApplications";

const Login = () => {
    const [otp, setOtp] = useState(["", "", "", "", "", ""]);

    const handleChange = (e, index) => {
        const newOtp = [...otp];
        newOtp[index] = e.target.value;
        // Move focus to the next input when a digit is entered
        if (e.target.value.length === 1 && index < otp.length - 1) {
            document.getElementById(`otp-input-${index + 1}`).focus();
        }
        setOtp(newOtp);
    };

    const loginForm = (
        <section className="form-section">
            <img src="../images/login-banner.jpg" className="banner-login"/>
            <div className="container">
                <div className="row row-gap-3">
                    <div className="col-lg-7 me-auto">
                        <div className="card rounded-0 shadow-none p-3 border-0 p-lg-5">
                            <form>
                                <div className="mb-3">
                                    <label htmlFor="exampleInputCompanyName" className="form-label">Company
                                        Name</label>
                                    <input type="text" className="form-control" id="exampleInputCompanyName"
                                           aria-describedby="CompanyName"/>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleInputPassword1"
                                           className="form-label">Password</label>
                                    <input type="password" className="form-control" id="exampleInputPassword1"/>
                                </div>
                                <div className="mb-3 form-check">
                                    <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
                                    <label className="form-check-label" htmlFor="exampleCheck1">Remember Me</label>
                                </div>
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setTimeout(() => {
                                            window.location.href = '/find-booking';
                                        }, 1000);
                                    }}
                                >
                                    Submit
                                </button>

                                <div className="mt-3 d-flex flex-wrap gap-3">
                                    <a href="forgot-password"
                                       className="text-decoration-none text-black fw-500">Forgot Password</a>
                                    <a href="register"
                                       className="ms-sm-auto text-decoration-none text-black fw-500">Create New
                                        Account</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
    const otpForm = (
        <section className="form-section">
            <img src="../images/forgot-banner.jpg" className="banner-login"/>
            <div className="container">
                <div className="row row-gap-3">
                    <div className="col-lg-7 me-auto">
                        <div className="card rounded-0 shadow-none p-3 border-0 p-lg-5">
                            <div className="head text-center mb-4">
                                <h3 className="mb-2">OTP Verification</h3>
                                <p className="m-0">Enter OTP Code sent to +91 9888******48</p>
                            </div>

                            <form>
                                <div className="otp-field mb-4 d-flex justify-content-between">
                                    {otp.map((digit, index) => (
                                        <input
                                            key={index}
                                            type="text"
                                            value={digit}
                                            onChange={(e) => handleChange(e, index)}
                                            id={`otp-input-${index}`}
                                            maxLength={1}
                                            className="otp-input"
                                        />
                                    ))}
                                </div>

                                <p className="resend mb-0 text-center">
                                    Didn't receive code?{" "}
                                    <a href="#" className="d-block fw-500 text-decoration-none"
                                       style={{color: "#2196f3"}}>
                                        Resend Code
                                    </a>
                                </p>

                                <button type="submit" className="btn btn-primary mt-4">
                                    Verify & Proceed
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

    return (
        <>
            <section className="login-module">
                <div className="container">
                    <div className="main-head py-3 py-lg-5">
                        <h3>Get Started Now!</h3>
                        <p className="m-0">Enter Credentials to get started now!</p>
                    </div>
                </div>
            </section>
            {loginForm}

            <DownloadApplications/>
        </>
    )
}
export default Login
