import React, {Suspense} from "react";
import {
    Route,
    BrowserRouter as Router,
    Routes, Navigate
} from "react-router-dom";

import MainLayout from "../layout"
import {history} from "../reducers/store";
import frontMenu from "./frontRoutes";
import {connect} from "react-redux";
import ScrollToTop from "../web/scrollTopComponent";

const RouteList = (props) => {
    let {currentUser = {}} = props;
    return (
        <>
            <Router history={history}>
                <ScrollToTop/>
                <Routes>
                    <Route path={"/"} element={<MainLayout/>}>
                        {frontMenu.map((item) => {
                            let {component: Component, path} = item
                            return (
                                <Route path={path} element={<Component/>}/>
                            )
                        })}
                    </Route>
                </Routes>
            </Router>
        </>

    );
};

const mapStateToProps = ({global}) => ({
    currentUser: global.currentUser,
    rights: global.rights
})
export default connect(
    mapStateToProps,
    null
)(RouteList)
