import React, {Component, Suspense, useEffect,} from 'react'
import "./styles/common.css"
import {connect} from "react-redux";
import Routes from "./routes/routes"
import {hideLoader} from "./actions/loader";
import flightLoader from "./assets/imgs/fligh-loader.gif"
class App extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // dispatch({type: "SEARCH_FLIGHT", flightObj: {}})
        this.props.dispatch(hideLoader())
    }


    render() {
        let {isLoading} = this.props;
        return (
            <div className="App">
                {isLoading ? <div className={'loader_outer_view'}>
                    <div className={'loader_inner'}>
                        <div className="sk-chase sk-primary">
                            <img src={flightLoader}/>
                        </div>
                        <div className={'spinnerText'}>
                            Loading ...
                        </div>
                    </div>
                </div> : null}


                <Routes {...this.props}/>

                <div id={'confirm-dialog'}></div>

            </div>
        );
    }
}


const mapStateToProps = ({global, router}) => ({
    isLoading: global.isLoading,
    currentUser: global.currentUser
})
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(App)

